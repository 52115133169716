<template>


    <b-card>
      


            <p    :class="isSidebar ? 'p_sidebar' : 'p_dashboard_link'">Comparte este link y gana por las transacciones de tus referidos</p>
    
    
        <b-input-group style="margin-top:5px">
    
          <b-form-input
            v-model="link"
            readonly
          />
          <b-input-group-append>
            <b-button
              @click="onCopyLink"
              variant="outline-primary"
            >
    
               <feather-icon
         icon="CopyIcon"
         size="15"
        
         /> 
            </b-button>
          </b-input-group-append>
        </b-input-group>

 

        <a v-if="!isSidebar" href="https://youtu.be/Ubye2A6lCZ0" target="_blank">
<!--
          <b-button
               
               variant="primary"
               block
               size="sm"
               style="margin-top:10px"
             
             >

             <b>Ver tutorial de uso <feather-icon
         
                                                       icon="YoutubeIcon"
                                                       size="21"
                                                       style="margin-left: 5px;"
                                                     /> </b>

             </b-button>
             -->
        </a>

      </b-card>
    
   
        
        </template>
        
        <script>
        import { BRow,BButton,VBTooltip,VBPopover, BCol,BInputGroup, BFormInput, BInputGroupPrepend, BInputGroupAppend, BFormGroup,BCard } from 'bootstrap-vue'
        
        export default {
          components: {
            BRow,
            BCol,
            BCard,
            BButton,
            BInputGroup, BFormInput, BInputGroupPrepend, BInputGroupAppend, BFormGroup,
          },
          directives: {
              'b-popover': VBPopover,
        'b-tooltip': VBTooltip,
      },
      props: ['promoCode', 'isSidebar', 'displayName'],
          data() {
        

    
            return {
            
          
                link: "",
                isMobile:this.$isMobile,
        
            }
          },
          created() {

            this.link=window.location.host+"/invitacion/"+this.promoCode

        
          },
          mounted() {
        
    
          
        
          },
          methods: {
        
            onCopyLink() {

              if (this.isMobile) {

                if (navigator.share) {
                  navigator.share({
                    title: 'EQCoop - Link de referidos de' + this.displayName,
                    text: "Hola soy " + this.displayName + " y te invito a formar parte de EQCoop y gana dinero por las transacciones de tus referidos, Registrate en el siguiente link: ",
                    url: "invitacion/" + this.promoCode,
                  })
                    .then(() => console.log('Successful share'))
                    .catch((error) => console.log('Error sharing', error));
                }

                } else {


                // Copy the text inside the text field
                navigator.clipboard.writeText(this.link);

                this.$toast.success('Link de referidos copiado!', {
                          position: 'top-right',
                          timeout: 1500,
                          closeOnClick: true,
                          pauseOnFocusLoss: false,
                          pauseOnHover: true,
                          draggable: true,
                          draggablePercent: 0.4,
                          showCloseButtonOnHover: false,
                          hideProgressBar: true,
                          closeButton: 'button',
                          icon: true,
                          rtl: false,
                        })

                }


  
        }
  
            
        
          },
        }
        </script>
        
        <style lang="scss" >
        
        
        
        </style>
        