<template>

    <b-link :href="anuncio.url"  >

                                        

<b-img oncontextmenu="return false;" style=" border-radius:10px; margin-bottom:10px"   :src="anuncio.urlImagen" :alt="anuncio.title"></b-img>


</b-link>   





    
      </template>
      
      <script>
      import {
        BImg, BLink
      } from 'bootstrap-vue'
      
      
      
      export default {
        components: {
         

          BImg,
          BLink
  
        },
        directives: {

        },
        props: ['anuncio'],
        data() {
      
      
          return {

      
          }
        },
        computed: {
      
        },
        watch: {
      
        },
        mounted() {
      
        },
        methods: {
  
  
         
      
        },
      }
      </script>
      
      <style lang="scss">
      
      
      
      </style>
      