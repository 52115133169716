<template>

    <div>
  
 
  
  
    <b-modal
        ref="modalInversion"
        centered
        hide-footer
        :title="title"
        :size="size"
        @hidden="closeModalInversion"
      >
        <div class="d-block text-center">
  
  
                    <b-row>
  

                      <b-col v-if="showDetalles" cols="12" sm="12" md="12" lg="12" xl="12" class="sin_padding"> 

                        <b-row>

                          <b-col cols="12" sm="12" md="12" lg="12" xl="12" > 

                                <b-card style="margin-top:40px">

                                  <b-avatar
                                              variant="light"
                                              :src="icon"
                                        
                                              class="border_ahorro"
                                              />
                                    <b-row>

                                      <b-col cols="12" sm="12" md="2" lg="2" xl="2"></b-col>


                                      <b-col cols="12" sm="12" md="8" lg="8" xl="8">

                                        <b-skeleton v-if="loading"  width="100%" height="10px" style="    margin-top: 10px;"></b-skeleton>
                       
                                        <p v-else style="font-weight: bold; font-size:16px; text-align: center; margin-top:5px;margin-bottom:10px">Fondo {{opcionFondo}}</p>


                                      </b-col>

                                      <b-col cols="12" sm="12" md="2" lg="2" xl="2"></b-col>


                                      <b-col v-if="loading" cols="12" sm="12" md="12" lg="12" xl="12">

                                        <b-skeleton   width="100%" height="80px" style="    margin-bottom: 0px;"></b-skeleton>
                       

                                        </b-col>

                                
                                      <b-col v-else cols="12" sm="12" md="12" lg="12" xl="12">

                                        <div  class="biografiatext">
                                              <read-more   more-str="Más información" :text="descripcionFinal" link="#" less-str="Menos información" :max-chars="100"></read-more>

                                      </div>

                                        </b-col>
                                            
                               

              

                                      </b-row>

                                  

                                </b-card>



                                </b-col>


                               


                                <b-col cols="4" sm="4" md="4" lg="4" xl="4" style="padding-right: 2px;" > 

                                <b-card>

                                  <div align="center" style="margin-bottom:5px">

                                    <img src="/img/rentabilidad.svg" style="width: 35px;"  />


                                  </div>

                                


                                  <p style="margin-bottom:10px; text-align: center;font-weight: bold;font-size:12px;">Rendimiento</p>
                                  <p class="rendimiento" style="font-size: 22px; margin-bottom:10px"> %</p>
                                  <p  style="font-size:12px; line-height: 14px;margin:0px">Según el mercado histórico</p>


                                  </b-card>
                                  </b-col>

                                  <b-col cols="4" sm="4" md="4" lg="4" xl="4" style="padding-right: 2px;padding-left: 2px;" > 

                                    <b-card>

                                      <div align="center" style="margin-bottom:5px">

                                        <img src="/img/calendar.svg" style="width: 35px;"  />


                                          </div>

                                      <p style="margin-bottom:10px;  text-align: center;font-size:12px;font-weight: bold;">Duración</p>

                                      <p class="rendimiento" style="font-size: 22px; margin-bottom:10px">  <b-skeleton v-if="loading"  width="100%" height="20px" style="    margin-bottom: 0px;"></b-skeleton><span v-else>{{timeDaysFinal}}</span></p>
                                      <p  style="font-size:12px;line-height: 14px; margin:0px">Días desde que activó el fondo</p>

                                  </b-card>
                                  </b-col>

                                  <b-col cols="4" sm="4" md="4" lg="4" xl="4"  style="padding-left: 2px;"> 

                                    <b-card>

                                      <div align="center" style="margin-bottom:5px">

                                        <img src="/img/dolar.svg" style="width: 35px;"  />


                                          </div>

                                      <p style="margin-bottom:10px; text-align: center;font-size:12px;font-weight: bold;">Monto mínimo</p>


                                      <p class="rendimiento" style="font-size: 22px; margin-bottom:10px">  <b-skeleton v-if="loading"  width="100%" height="20px" style="    margin-bottom: 0px;"></b-skeleton><span v-else>{{inversionMinFinal}}</span></p>
                                      <p  style="font-size:12px; margin:0px; line-height: 14px;">En valor monetario USDT</p>

                                    </b-card>
                                    </b-col>

                                    <b-col   cols="12" sm="12" md="12" lg="12" xl="12" align="center" >
                                      <b-skeleton v-if="loading"  width="100%" height="40px" style="    margin-bottom: 0px;"></b-skeleton>
                       
                                    <b-button v-else-if="showButton"   class="animacion_button" block  @click="siguiente()" style="border-radius:50px"  variant="primary" >  Quiero ahorrar en este fondo </b-button>

                                
                                      <b-card style="margin-bottom:0px" v-else>
                                      <img
                                          alt="Ok"
                                          src="/img/like.svg"
                                          style="width: 70px;"
                                          >

                                          <p style="text-align: center;margin-bottom:0px;  font-size:16px; margin-top: 15px">Usted ya tiene activado este fondo de ahorro en USDT, por favor seleccione otro fondo</p>

                                        </b-card>

                                    </b-col>


                        </b-row>

                        </b-col>

                        <b-col v-if="showSaldo" cols="12" sm="12" md="12" lg="12" xl="12"   class="sin_padding" >

                

                         
                               <saldo :key="componentKeySaldoAhorro"  :opcion-fondo="opcionFondo"  :user-id="userId" :token-auth="tokenAuth"  />
        
                            
                           

                          </b-col>

          
  
                     
          
  
                  </b-row>
         
         
  
        </div>
  
      </b-modal>
  
  
  </div>
  
  </template>
  
  <script>
  import {
    VBTooltip,VBPopover, BButton, BRow, BCol, BCard, BSkeletonImg,BSkeleton, BAvatar, BLink, BFormSelect
  } from 'bootstrap-vue'
  import Saldo from './Saldo.vue'
  
  export default {
    components: {
      BButton,
      Saldo,
      
      BLink,
      BAvatar,
      BSkeleton,
      BSkeletonImg,
      BRow,
      BCol,
      BCard,
      BFormSelect
    },
      directives: {
            'b-popover': VBPopover,
      'b-tooltip': VBTooltip,
    },
    props: ['userId','tokenAuth','optionFondos', 'timeDays', 'descripcion', 'rendimiento', 'inversionMin'],
    data() {

      let icon="";

      let opcionFondo=this.optionFondos;

      let descripcionFinal= this.descripcion;
      let timeDaysFinal= this.timeDays;
      let inversionMinFinal= this.inversionMin;
      let rendimientoFinal= this.rendimiento;

      switch (this.optionFondos) {
            case "Oportunidad":
              icon="/img/oportunidad.svg";           
              break
            case "Rentable":
             icon= "/img/rentable.svg";            
              break
            case "Acumulativo":

                 icon="/img/acumulativo.svg" ;         
  
              break

              case "Productivo":

           icon="/img/productivo.svg";
            break

          }
  
          
  
      return {

       title:"Fondo de ahorros en USDT",
       icon:icon,
       opcionFondo:opcionFondo,
       componentKeySaldoAhorro:0,
       loading:false,
       showButton: true,
       showSaldo:false,
       size:"sm",
       showDetalles:true,
       descripcionFinal:descripcionFinal,
       timeDaysFinal:timeDaysFinal,
       inversionMinFinal:inversionMinFinal,
       rendimientoFinal:rendimientoFinal,
       options: [
        { value: 'Acumulativo', text: 'Fondo Acumulativo' },
        { value: 'Productivo', text: 'Fondo Productivo' },
          { value: 'Rentable', text: 'Fondo Rentable' },
          { value: "Oportunidad", text: 'Fondo Oportunidad' },
        ]
       
      
      }
    },
    computed: {
  
  
  
    },
    watch: {
  
 
  
  
  
    },
    mounted() {
  
  
      this.$refs.modalInversion.show();

      this.$eventBus.$on('closeModalInversionEvent', () => {
        this.$refs.modalInversion.hide();
 
    });

    this.$eventBus.$on('atrasInversion', () => {
      this.showDetalles=true;
    this.showSaldo=false;
    this.size="sm";
 
    });

    this.$eventBus.$on('reiniciarSaldoAhorro', () => {
      this.componentKeySaldoAhorro += 1

 
    });
  
  
    },
    methods: {
  
    closeModalInversion(){
    
    this.$eventBus.$emit('reiniciarModalInversion')
  
  },
  siguiente(){

    this.showDetalles=false;
    this.showSaldo=true;
    this.size="lg";

  },
  onChange(opcion){

      this.opcionFondo=opcion;

      switch (this.opcionFondo) {
            case "Oportunidad":
              this.icon="/img/oportunidad.svg";           
              break
            case "Rentable":
             this.icon= "/img/rentable.svg";            
              break
            case "Acumulativo":

                 this.icon="/img/acumulativo.svg" ;         
  
              break

              case "Productivo":

           this.icon="/img/productivo.svg";
            break

          }

      this.getFondosAhorros();

      this.$eventBus.$emit('reiniciarSaldoAhorro')



      },

      getFondosAhorros(){
       this.loading=true;


     const userId_json = {
        userId: this.userId, type: this.opcionFondo }

      const user_string = JSON.stringify(userId_json)

        const dataEncripted = this.$encryptBackoffice.encrypt(user_string)

            this.$https.post('/inversion/getFondosAhorrosByUser/', { tokenAuth: this.tokenAuth, datos: dataEncripted }).then(response => {
                

              if (response.data.code == 200) {
                this.loading = false;

              
             

                  this.descripcionFinal= response.data.descripcion;
                  this.rendimientoFinal= response.data.rendimiento;
                  this.timeDaysFinal=  response.data.timeDays;
                  this.inversionMinFinal= response.data.inversion_min;

              

                  if(response.data.idFondo === ""){
              
                    this.showButton=true;

                  }else{
                    this.showButton=false;

                  }

             
              } else {




                if (response.data.code == 401) {

                    this.$toast.error(response.data.message, {
                    position: 'top-right',
                    timeout: 3010,
                    closeOnClick: true,
                    pauseOnFocusLoss: false,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.4,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: 'button',
                    icon: true,
                    rtl: false,
                  })
                  
                  localStorage.removeItem('userData')

                  

                  
                  

                  

                  
                  

                  // Redirect to login page
                  this.$router.push({ name: 'auth-login' })
                } else {
                  this.getFondosAhorros();
                }
              }
            }).catch(error => {
                this.getFondosAhorros();
            })


            },

  
     
}

  }
  </script>
  
  <style lang="scss">
  
  
  
  
  
  </style>
  