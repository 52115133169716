<template>

<div>

  <div v-if="loadingCheck">
    <b-skeleton width="100%" v-if="loading" height="180px" type="button" style="margin:0px"></b-skeleton>

  </div>

  <div v-else-if="hasFondo">

<div v-if="isProfileVerificatedFinal">

  <div class="card">


<div class="card-body card_body_saldos" style="padding:15px 10px 5px 10px">

  <b-row>

            <b-col cols="2" sm="2" md="2" lg="2" xl="2" style="text-align: left">

              
                <b-skeleton width="100%" v-if="loading" height="10px" type="button" style="margin:0px"></b-skeleton>

              

              <feather-icon
               v-else
              icon="RefreshCcwIcon"
              size="18"
              @click="refresh"
              style="cursor:pointer;color:#fff"
              />


       </b-col>



        <b-col cols="8" sm="8" md="8" lg="8" xl="8" >

       <p class="saldo_txt" style="text-align: center;margin:0px; font-size:14px; font-weight: bold;">Fondo {{optionFondos}} en USDT</p>


      </b-col>

      <b-col cols="2" sm="2" md="2" lg="2" xl="2" style="text-align: right">


          
        <b-skeleton width="100%" v-if="loading" height="10px" type="button" style="margin:0px"></b-skeleton>

              

          <feather-icon
          v-else-if="hasActivateFondo"
          icon="EyeIcon"
          size="21"
          @click="openDetalles"
          style="cursor:pointer;color:#fff"
          />

      </b-col>



      <b-col cols="8" sm="8" md="8" lg="8" xl="8" >
      

            <b-skeleton v-if="loading" width="100%"  height="35px" style="margin-bottom:0px;     margin-top: 15px;"  ></b-skeleton>
                 

            <p style=" margin-top: 20px;" v-else class="saldo2_txt">{{balance}}<small class="currency" style="    top: 15px;">USDT</small></p>
       
      </b-col>

       <b-col cols="4" sm="4" md="4" lg="4" xl="4" style="padding: 0;">

        <b-skeleton v-if="loading" style="margin-top: 15px;margin-bottom: 0px;" width="100%"  height="35px"  ></b-skeleton>
                 
    
         
             
        <b-form-select
        v-else
              v-model="optionFondos"
              :options="options"
              @change="onChange($event)"
              style="margin-top: 10px;"
              size="sm"
              />


       </b-col>


       <b-col cols="12" sm="12" md="12" lg="12" xl="12" style="padding: 0;">

        <p  class="saldo_txt" style="margin-bottom:0px;font-size:10px; margin-top:3px; text-align:center !important">Este servicio es brindado por EQCoop SYSTEMS, LLC</p>


        </b-col>

     


  </b-row>


</div>

  <b-card-footer>

    <b-row v-if="loading">

      <b-col  cols="1" sm="1" md="1" lg="1" xl="1" align="center" ></b-col>

        <b-col  cols="10" sm="10" md="10" lg="10" xl="10" align="center" >

          <b-skeleton width="100%"  height="40px" style="margin-top:13px; margin-bottom: 13px;"></b-skeleton>

          
      </b-col>

      <b-col  cols="1" sm="1" md="1" lg="1" xl="1" align="center" ></b-col>


      
    </b-row>

    <div v-else>

      <b-row v-if="hasActivateFondo">

        <detalles-inversion v-if="openModalDetallesInversion" :balance="balance" :key="componentKeyModalDetallesInversion" :date-transaction="DateTransaction" :date-expiration="DateExpiration" :option-fondos="optionFondos" :time-days="timeDays" :rendimiento="rendimiento"  :user-id="userId" :token-auth="tokenAuth"  />
  

       

        <b-col v-if="permitirUpdateFondo" cols="12" sm="12" md="12" lg="12" xl="12" align="center" >

          <modal-aumentar-fondos v-if="openModalAumentarFondos" :id-fondo="idFondo" :balance="balance" :comision="comision" :key="componentKeyModalAumentarFondos" :option-fondos="optionFondos" :time-days="timeDays" :rendimiento="rendimiento"  :user-id="userId" :token-auth="tokenAuth"  />
  

          <b-button style="    margin-top: 13px;margin-bottom: 13px;"  @click="aumentar()" class="animacion_button" variant="primary" >    Aumentar saldo USDT al fondo </b-button>
          

          </b-col>

          <b-col v-else cols="12" sm="12" md="12" lg="12" xl="12" align="center" >

            <b-alert
                                    variant="secondary"
                                    show
                                    style="margin-top:0px; padding: 8px;"
                                  >
                                    <div class="alert-body">

                                      <p style="text-align: center;font-size:13px">Estimado usuario, usted podrá volver a realizar un nuevo aumento a este fondo a partir del <b>{{ DateProxUpdateFondo }}</b></p>

                                    </div>
                                  </b-alert>

         



           </b-col>


      </b-row>

      <b-row v-else>

   
              <b-col v-if="optionFondos === 'Acumulativo'"  cols="12" sm="12" md="12" lg="12" xl="12" align="center" >

                  <b-button     class="animacion_button"  @click="invertir()" style="   margin-top: 14px;margin-bottom: 14px;"  variant="primary" > Comenzar a ahorrar con este fondo </b-button>

                  
              </b-col>

              <b-col v-else  cols="12" sm="12" md="12" lg="12" xl="12" align="center" >
                <b-alert
                                    variant="primary"
                                    show
                                    style="margin-top:0px; padding: 8px;"
                                  >
                                    <div class="alert-body">

                                      <p style="text-align: center;font-size:13px">Estimado usuario, actualmente este fondo de ahorro se encuentra inactivo. </p>

                                    </div>
                                  </b-alert>

              </b-col>

         

          </b-row>




  </div>
  
</b-card-footer>




<modal-inversion v-if="openModalInversion" :key="componentKeyModalInversion" :inversion-min="inversionMin" :option-fondos="optionFondos" :time-days="timeDays" :descripcion="descripcion" :rendimiento="rendimiento"  :user-id="userId" :token-auth="tokenAuth"  />
  
</div>
</div>

</div>

</div>




    


</template>

<script>
import {
BButton,BCardFooter,BLink,BFormSelect,VBTooltip, VBPopover, BRow, BCol, BCard,BSkeleton, BAvatar, BAlert
} from 'bootstrap-vue'
import ModalInversion from '../inversiones/ModalInversion.vue'
import ModalAumentarFondos from '../inversiones/ModalAumentarFondos.vue'
import DetallesInversion from '../inversiones/DetallesInversion.vue'


export default {
components: {
BButton,
BCardFooter,
BAlert,
BRow,
BCol,
BLink,
BCard,
BAvatar,
ModalAumentarFondos,
ModalInversion,
BFormSelect,
BSkeleton,
DetallesInversion,
},
directives: {
'b-popover': VBPopover,
'b-tooltip': VBTooltip,
},
props: ['userId','tokenAuth','isProfileVerificated'],
data() {



return {
  loading: true,
  loadingCheck:true,
  hasFondo:false,
  isProfileVerificatedFinal: this.isProfileVerificated,
  balance: "0.00",
  saldoDisponible:0,
  hasActivateFondo:false,
  color: "#242526",
  overlay:"#000000e6",
  title:"",
   radius:"15px",
   indentColor:"#b0b3b8",
   optionFondos: "Acumulativo",
   descripcion:"",
   rendimiento:0,
   comision:0,
   DateTransaction:"",
   DateExpiration:"",
   componentKeyModalInversion:0,
   componentKeyModalDetallesInversion:0,
   componentKeyModalAumentarFondos:0,
   timeDays:0,
   inversionMin:0,
   openModalInversion:false,
   idFondo:"",
   openModalAumentarFondos:false, 
   openModalDetallesInversion:false, 
   permitirUpdateFondo:false,
   DateProxUpdateFondo:"",
   options: [
   { value: 'Acumulativo', text: 'Acumulativo' },
   { value: 'Productivo', text: 'Productivo' },
    { value: 'Rentable', text: 'Rentable' },
    { value: "Oportunidad", text: 'Oportunidad' },
  ],
  
}
},
computed: {

},
watch: {




},
created(){






},
mounted() {


this.checkHasFondo();



this.$eventBus.$on('reiniciarSaldoInversion', (opcion) => {



  this.optionFondos=opcion;
     this.refresh();
 
    });

this.$eventBus.$on('reiniciarModalInversion', () => {
      this.componentKeyModalInversion += 1
      this.openModalInversion = false;
 
    });

    this.$eventBus.$on('reiniciarModalAumentarFondos', () => {
      this.componentKeyModalAumentarFondos += 1
      this.openModalAumentarFondos = false;
 
    });

    

    this.$eventBus.$on('closeModalDetallesInversion', () => {
    
      this.componentKeyModalDetallesInversion += 1
      this.openModalDetallesInversion = false;
      
    });



},
methods: {

 

    invertir(){

      this.openModalInversion= true;
    },

    openDetalles(){
      this.openModalDetallesInversion=true;
    },
onChange(opcion){

    this.optionFondos=opcion;

     this.getFondosAhorros();


},

refresh() {
    this.getFondosAhorros();

},
aumentar(){

  this.openModalAumentarFondos= true;

},

checkHasFondo(){
  this.loadingCheck=true;

  const userId_json = { userId: this.userId }



      this.$https.post('/inversion/checkFondo/', { tokenAuth: this.tokenAuth, datos: userId_json }).then(response => {
          

        if (response.data.code == 200) {
          this.loadingCheck = false;

        
          this.hasFondo= response.data.hasFondo;
          this.getFondosAhorros();
          
        } else {




          if (response.data.code == 401) {

              this.$toast.error(response.data.message, {
              position: 'top-right',
              timeout: 3010,
              closeOnClick: true,
              pauseOnFocusLoss: false,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.4,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: 'button',
              icon: true,
              rtl: false,
            })
            
            localStorage.removeItem('userData')



            // Redirect to login page
            this.$router.push({ name: 'auth-login' })
          } else {
            this.checkHasFondo();
          }
        }
      }).catch(error => {
          this.checkHasFondo();
      })

},

getFondosAhorros(){
    this.loading=true;


     const userId_json = { userId: this.userId, type: this.optionFondos }



this.$https.post('/inversion/getFondosAhorrosByUser/', { tokenAuth: this.tokenAuth, datos: userId_json }).then(response => {
    

  if (response.data.code == 200) {
    this.loading = false;

  
    this.balance= response.data.balance;
      if(Number(this.balance) === Number("0")){
        this.balance="0.00";
      }
      this.hasActivateFondo= response.data.hasActivateFondo;

      this.descripcion= response.data.descripcion;
      this.rendimiento= response.data.rendimiento;
      this.timeDays=  response.data.timeDays;
      this.comision= response.data.comision;

      this.permitirUpdateFondo=response.data.permitirUpdateFondo;

      this.DateProxUpdateFondo=response.data.DateProxUpdateFondo;

      this.DateTransaction= response.data.DateTransaction;
      this.DateExpiration=response.data.DateExpiration

      this.inversionMin= response.data.inversion_min;

      this.idFondo= response.data.idFondo;
     
     
    
  } else {




    if (response.data.code == 401) {

        this.$toast.error(response.data.message, {
        position: 'top-right',
        timeout: 3010,
        closeOnClick: true,
        pauseOnFocusLoss: false,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.4,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: 'button',
        icon: true,
        rtl: false,
      })
      
      localStorage.removeItem('userData')



      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    } else {
       this.getFondosAhorros();
    }
  }
}).catch(error => {
     this.getFondosAhorros();
})


},
}
}

</script>

<style lang="scss">







</style>
