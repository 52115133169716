<template>

<div>

    
         
  <b-row>

    <b-col sm="12" md="12" lg="6" xl="6" cols="12" style="margin-bottom:15px">



        <b-alert
            variant="secondary"
            show
            style="margin-top:0px;margin-bottom: 10px;"
          >
            <div class="alert-body">
              <b-skeleton v-if="loading" style="margin-bottom:0px" width="100%" height="40px"  ></b-skeleton>

              <p v-else class="saldoDisponible">{{saldoDisponible}}<small class="currency2">USDT</small></p>
              <p style="font-size: 12px;    margin: 0;">Saldo disponible para utilizar</p>

            
            </div>
          </b-alert>

          <b-form
              autocomplete="off"
              @submit.prevent="onSubmit"
            >

            <b-row>

                    <b-col
                      cols="12"
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                      >

                      

                                  <p
                          class="textLabel"
                          style="text-align: center;margin-top: 5px;margin-bottom: 5px;"
                        >
                          ¿Cuánto dinero vas a enviar al fondo? (*):</p>
                          <b-skeleton v-if="loading" style="margin-bottom:0px" width="100%" height="60px"  ></b-skeleton>

                          <AutoNumericVue
                          v-else
                          v-model="mount"
                          required
                          type="text" inputmode="decimal"
                          :readonly="isDisabled"
                          autocus
                          @input="handleInput2"
                            @blur="handleInput"
                          class="autonumeric_input"
                          :options="json_opciones"
                          :placeholder="'$0.00'"
                      ></AutoNumericVue>

                      </b-col>



                          <b-col
                                  sm="12"
                                  md="12"
                                  lg="12"
                                  xl="12"
                                >
                                  <p
                                    class="textLabel"
                                    style="text-align: center;margin-top: 0px;margin-bottom: 10px;"
                                  >
                                    Ingrese su código PIN (*): <feather-icon
                                                      icon="HelpCircleIcon"
                                                      size="16"
                                                        v-b-popover.hover.bottom.html="'Si aún no tiene registrado un código PIN o se le olvidó cual era, puede dirigirse a <b>Mi Perfil > Código PIN de seguridad </b> para registrarlo o modificarlo'"
                                                      title="Código PIN de seguridad"
                                                      style="margin-right: 5px;cursor:pointer;"
                                                      /> </p>

                                  <PincodeInput
                                    v-model="pin"
                                    placeholder="-"
                                    :length="6"
                                    :autofocus="false"
                                    :disabled="isDisabled"
                                    :secure="true"
                                    required
                                  />

                                </b-col>

              



                                <b-col
                                cols="12"
                                  sm="12"
                                  md="12"
                                  lg="12"
                                  xl="12"

                                  style="justify-content: space-between;display: flex"
                                >

                                <b-button
                                  style="margin-right:10px"
                                  variant="outline-secondary"
                                 
                                  
                                  @click="atras()">

                                    <feather-icon  icon="ArrowLeftCircleIcon" size="15" style="margin-right: 5px;"/> Atras
                                    

                                  </b-button>

                                  <b-button
                                  v-if="loading" 
                                    id="btn_invertir"
                                    ref="btn_invertir"
                                    type="submit"
                                    variant="primary"
                                    
                                    :disabled="true"
                                  >

                                    Ahorrar

                                  </b-button>


                                  <b-button
                                  v-else
                                    id="btn_invertir"
                                    ref="btn_invertir"
                                    type="submit"
                                    variant="primary"
                                    
                                    :disabled="isDisabled"
                                  >

                                    Ahorrar

                                  </b-button>

                                </b-col>
                                <b-col
                                cols="12"
                                  sm="12"
                                  md="12"
                                  lg="12"
                                  xl="12">

                                <b-alert
                          variant="primary"
                          show
                          style="margin-top:15px"
                        >
                          <div class="alert-body">
                            <b-skeleton v-if="loading" style="margin-bottom:10px" width="100%" height="10px"  ></b-skeleton>
                             <p v-else style="text-align: left;font-size:13px; margin-bottom: 0px;">(*) El monto mínimo es de <b>${{inversionMin}} USDT</b></p>
                             <b-skeleton v-if="loading" style="margin-bottom:10px" width="100%" height="10px"  ></b-skeleton>
                                <p v-else style="text-align: left;font-size:13px; margin-bottom: 0px;">(*) El monto máximo es de <b>${{maxFondoAhorro}} USDT</b> </p>

                          </div>
                        </b-alert>


                      </b-col>

            </b-row>
            
            
            </b-form>



    

      </b-col>


      <b-col sm="12" md="12" lg="6" xl="6" cols="12">

 

        <p style="font-weight: bold; font-size:16px; text-align: center; margin-top:15px;margin-bottom:10px">Ganancias que recibirá</p>

<hr>

              <div class="table-responsive ">
                  <table class="table table-bordered" style="text-align: center;">

                    <tbody>

                      <tr>

                        <td class="background_table">Cada día:</td>
                        <td class="background_td_table"> <p style="font-size:16px; margin: 0px;"><span style="font-size:12px">$</span>{{dineroDiarioRecibir}} <span style="font-size:10px">USDT</span></p></td>
                        </tr>

                        <tr>

                          <td class="background_table">Cada 30 días:</td>
                          <td class="background_td_table"> <p style="font-size:16px; margin: 0px;"><span style="font-size:12px">$</span>{{dineroMensualRecibir}} <span style="font-size:10px">USDT</span></p></td>
                          </tr>

                      <tr>

                        <td class="background_table">Dentro de {{timeDays}} días:</td>
                        <td class="background_td_table"> <p style="font-size:16px; margin: 0px;"><span style="font-size:12px">$</span>{{dineroTotalRecibir}} <span style="font-size:10px">USDT</span></p></td>
                      
                      </tr>

                      <tr>

                        <td class="background_table">Total a recibir: <br>(incluye comisión)</td>
                        <td class="background_td_table"> <p style="font-size:16px; margin: 0px;"><span style="font-size:12px">$</span>{{dineroTotalRecibirComision}} <span style="font-size:10px">USDT</span></p></td>

                        </tr>





                    
                    </tbody>
                  </table>
                  </div>
        
              <b-alert variant="secondary" style="margin-top: 5px !important; " show>
                <div class="alert-body">



                      <p style="font-size: 12px; margin-bottom:0;"> Este fondo se maneja en USDT y brindado por EQCoop System LLC. Haremos la conversión de sus fondos de manera automática. Recuerde que el mercado de inversiones puede tener caídas que serán notificadas a los clientes a través de los diferentes medios de comunicación de manera formal.</p>


                
              
                
                </div>
              </b-alert>


      </b-col>



  </b-row>
</div>
    
    </template>
    
    <script>
    import {
      VBTooltip, BButton,VBPopover,BForm,BAlert, BRow, BCol, BCard, BSkeleton, BSkeletonTable
    } from 'bootstrap-vue'
    import PincodeInput from 'vue-pincode-input'
    import AutoNumericVue from 'autonumeric-vue/src/components/AutoNumericVue';
    
    export default {
      components: {
        BButton,
        BForm,
        BSkeleton,
        BSkeletonTable,
        BAlert,
        BRow,
        BCol,
        BCard,
        AutoNumericVue,
        PincodeInput
      },
        directives: {
        'b-popover': VBPopover,
        'b-tooltip': VBTooltip,
      },
      props: ['userId','tokenAuth', 'opcionFondo'],
      data() {
    
        
        return {
            pin:"",
            isDisabled:false,
            mount:"",
            json_opciones:{},
            code200:false,
            loading:true,
            saldoDisponible:"",
            maxFondoAhorro:0,
            dineroMensualRecibir:"0.00",
            dineroTotalRecibir:"0.00",
            dineroDiarioRecibir:"0.00",
            dineroTotalRecibirComision:"0.00",
            comision:0,
            rendimiento:0,
            inversionMin:0,
            timeDays:0,

    
    
        
        }
      },
      computed: {
    
    
    
      },
      watch: {
    
    

    
     
    
      },
      mounted() {
    
    
    
          
    
       
    
        this.checkInversion();
    
    
      },
      methods: {
     
        checkInversion(){

            this.loading=true;


                    const userId_json = {
                    userId: this.userId, type: this.opcionFondo }

                    const user_string = JSON.stringify(userId_json)

                    const dataEncripted = this.$encryptBackoffice.encrypt(user_string)

                    this.$https.post('/inversion/getBalanceInversion/', { tokenAuth: this.tokenAuth, datos: dataEncripted }).then(response => {


                    if (response.data.code == 200) {
                    this.loading = false;
                    this.maxFondoAhorro= response.data.maxFondoAhorro


                    this.saldoDisponible= response.data.balance;
                

                    this.comision= response.data.comision;
                    this.rendimiento= response.data.rendimiento;
                    this.timeDays=  response.data.timeDays;
                    this.inversionMin= response.data.inversion_min;

                    this.code200 = true;

                    this.json_opciones={allowDecimalPadding: "false", currencySymbol: "$", decimalCharacterAlternative: ",",decimalCharacter:".",  minimumValue: 0, maximumValue:this.maxFondoAhorro, modifyValueOnWheel: false };
    
                    



                    } else {




                    if (response.data.code == 401) {

                    this.$toast.error(response.data.message, {
                    position: 'top-right',
                    timeout: 3010,
                    closeOnClick: true,
                    pauseOnFocusLoss: false,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.4,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: 'button',
                    icon: true,
                    rtl: false,
                    })
                    
                    localStorage.removeItem('userData')

                    

                    
                    

                    

                    
                    

                    // Redirect to login page
                    this.$router.push({ name: 'auth-login' })
                    } else {

                        if (response.data.code == 404) {

                            this.loading=false;
                            this.code200=false;
                            

                        }else{
                            this.checkInversion();

                        }
                   
                    }
                    }
                    }).catch(error => {
                    this.checkInversion();
                    })


        },    
        atras(){
          this.$eventBus.$emit('atrasInversion');
  },

         onSubmit(event) {
          event.preventDefault();

  
            this.mount= Number(this.mount).toFixed(2);
    
    
          const userId_json = {
            userId: this.userId, pin: this.pin, mount: this.mount, type:this.opcionFondo
          }
    
    
    
          const user_string = JSON.stringify(userId_json)
    
          const dataEncripted = this.$encryptBackoffice.encrypt(user_string)
        
          this.isDisabled = true
    
          document.getElementById('btn_invertir').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Procesando'
    
          this.$https.post('/inversion/sendAhorro/', { tokenAuth: this.tokenAuth, datos: dataEncripted}).then(response => {
          this.isDisabled = false
    
        
      
            document.getElementById('btn_invertir').innerHTML = 'Ahorrar';
   
            if (response.data.code == 200) {
    

    
              this.$toast.success(response.data.message, {
                position: 'top-right',
                timeout: 3010,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.4,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: 'button',
                icon: true,
                rtl: false,
              });

              this.$eventBus.$emit('closeModalInversionEvent');
  
    
              this.$eventBus.$emit('reiniciarSaldoInversion', this.opcionFondo);
             
    
    
    
            } else {
              this.isDisabled = false
    
              this.$toast.error(response.data.message, {
                position: 'top-right',
                timeout: 3010,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.4,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: 'button',
                icon: true,
                rtl: false,
              })
    
              if (response.data.code == 401) {
                
                localStorage.removeItem('userData')

                 

    
                
                
    
                // Redirect to login page
                this.$router.push({ name: 'auth-login' })
              } else if (response.data.code == 503) {
                this.pin = ''
              }else if(response.data.code == 501){

                this.$eventBus.$emit('closeModalInversionEvent')
                      this.$swal({
                          title: 'Tu perfil debe estar verificado para realizar esta acción',
                          text: `¿Deseas verificar tu cuenta ahora?`,
                          icon: 'warning',
                          showCancelButton: true,
                          confirmButtonText: 'Verificar cuenta',
                          cancelButtonText: 'No',
                          customClass: {
                            confirmButton: 'btn btn-primary',
                            cancelButton: 'btn btn-outline-secondary ml-1',
                          },
                          buttonsStyling: false,
                        }).then(result => {
                          if (result.value) {
    
                            this.$router.push({ name: 'perfil', hash: '#verificacion' })
    
                          }
                        })
                    }else if(response.data.code=== 404){

                      this.$eventBus.$emit('closeModalInversionEvent')
  

                    }
            }
          }).catch(error => {
            this.$toast.error("Ocurrió un error inesperado", {
              position: 'top-right',
              timeout: 3010,
              closeOnClick: true,
              pauseOnFocusLoss: false,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.4,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: 'button',
              icon: true,
              rtl: false,
            })
          })
    
      
    
        
        },
       

        handleInput2(value){

     

          if(value === "" || value === "null" || value === null  ){


            
            

                this.dineroMensualRecibir="0.00";
                this.dineroDiarioRecibir="0.00";
                this.dineroTotalRecibir="0.00";
                this.dineroTotalRecibirComision="0.00";




            }else{

              let porcentaje=Number(this.rendimiento)/Number("100");

             this.dineroMensualRecibir= Number(Number(this.mount)  * Number(porcentaje)).toFixed(2);

             this.dineroDiarioRecibir=  Number(Number(this.dineroMensualRecibir) / Number("30")).toFixed(2);


       
             this.dineroTotalRecibir= Number(Number(this.dineroDiarioRecibir) * Number(this.timeDays)).toFixed(2);//esta es lo que recibe en 3 meses

              let mountComision= (Number(this.mount) * Number(this.comision))/Number("100");

  

             this.dineroTotalRecibirComision=  Number(Number(this.mount) - Number(mountComision) + Number(this.dineroTotalRecibir)).toFixed(2);
          
           

            }

        },

handleInput () {


var t = this.mount;

if(t === "" || t === "null" || t === null  ){



        this.dineroMensualRecibir="0.00";
        this.dineroDiarioRecibir="0.00";
                this.dineroTotalRecibir="0.00";
                this.dineroTotalRecibirComision="0.00";


    }else{



if(Number(this.mount) > Number(this.saldoDisponible)){



    this.$toast.error("El monto $"+this.mount+" USD supera su saldo de billetera.", {
    position: 'top-right',
    timeout: 3010,
    closeOnClick: true,
    pauseOnFocusLoss: false,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.4,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: 'button',
    icon: true,
    rtl: false,
});

        this.mount ="";
    this.dineroMensualRecibir="0.00";
    this.dineroDiarioRecibir="0.00";
                this.dineroTotalRecibir="0.00";
                this.dineroTotalRecibirComision="0.00";

}else{

    
  if(Number(this.mount) < Number(this.inversionMin)){

    this.$toast.error("El monto $"+this.mount+" USD debe ser mayor al monto mínimo de inversión de $"+this.inversionMin+" USD", {
    position: 'top-right',
    timeout: 3010,
    closeOnClick: true,
    pauseOnFocusLoss: false,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.4,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: 'button',
    icon: true,
    rtl: false,
});

        this.mount ="";
    this.dineroMensualRecibir="0.00";
    this.dineroDiarioRecibir="0.00";
                this.dineroTotalRecibir="0.00";
                this.dineroTotalRecibirComision="0.00";


  }else{

    let porcentaje=Number(this.rendimiento)/Number("100");

this.dineroMensualRecibir= Number(Number(this.mount)  * Number(porcentaje)).toFixed(2);

this.dineroDiarioRecibir=  Number(Number(this.dineroMensualRecibir) / Number("30")).toFixed(2);



this.dineroTotalRecibir= Number(Number(this.dineroDiarioRecibir) * Number(this.timeDays)).toFixed(2);//esta es lo que recibe en 3 meses

 let mountComision= (Number(this.mount) * Number(this.comision))/Number("100");

 this.dineroTotalRecibirComision=  Number(Number(this.mount) - Number(mountComision) + Number(this.dineroTotalRecibir)).toFixed(2);
          


  }



    

}




    }


},
        
    
       
      }
    }
    </script>
    
    <style lang="scss">
    
    
    
    
    </style>
    