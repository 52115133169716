<template>

    <div>
 
       <b-card
         v-if="loading"
         align="center"
       >

       <b-row>

        <b-col lg="10" md="10" sm="10" cols="10" xl="10">

          <b-link :to="{ name: 'transacciones'}"  >

            <h4 class="font-weight-bolder" style="text-align: left !important;    margin-bottom: 20px;">
               Ver mis últimos movimientos <svg class="arrow_more" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 20.633 20.633"><g><path d="M15.621 9.844 5.971.195C5.842.064 5.674.0 5.5.0c-.171.0-.343.064-.473.195L5.014.207c-.126.127-.197.299-.197.475v4.682c0 .178.071.348.197.471l4.481 4.482-4.481 4.479c-.126.126-.197.294-.197.475v4.68c0 .18.071.354.197.475l.013.01c.124.127.294.197.473.197s.348-.07.474-.197l9.647-9.646C15.881 10.531 15.881 10.104 15.621 9.844z"/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/></g><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/></svg>
              </h4>
            
</b-link>


      



          </b-col>

          <b-col lg="2" md="2" sm="2" cols="2" xl="2" style="text-align: right;" >

          <b-skeleton  width="100%" height="20px" style="margin:0px"></b-skeleton>


          </b-col>




        <b-col lg="2" md="2" sm="2" cols="2" xl="2">

            <b-skeleton type="avatar" width="40px" height="40px"></b-skeleton>



        </b-col>

        <b-col lg="8" md="8" sm="8" cols="8" xl="8">

            <b-skeleton width="100%"  height="10px" style="margin-bottom:5px"></b-skeleton>
            <b-skeleton width="70%"  height="10px" style="margin-bottom:5px"></b-skeleton>
            <b-skeleton width="50%"  height="10px" style="margin-bottom:5px"></b-skeleton>



            </b-col>


            <b-col lg="2" md="2" sm="2" cols="2" xl="2">

              <b-skeleton width="100%"  height="20px" style="margin-bottom:5px"></b-skeleton>
    <b-skeleton width="60%"  height="10px" style="margin-bottom:5px"></b-skeleton>


            </b-col>

            <b-col lg="12" md="12" sm="12" cols="12" xl="12">

                <hr>
            </b-col>

            <b-col lg="2" md="2" sm="2" cols="2" xl="2">

<b-skeleton type="avatar" width="40px" height="40px"></b-skeleton>



</b-col>

<b-col lg="8" md="8" sm="8" cols="8" xl="8">

  <b-skeleton width="100%"  height="10px" style="margin-bottom:5px"></b-skeleton>
            <b-skeleton width="70%"  height="10px" style="margin-bottom:5px"></b-skeleton>
            <b-skeleton width="50%"  height="10px" style="margin-bottom:5px"></b-skeleton>


</b-col>


<b-col lg="2" md="2" sm="2" cols="2" xl="2">

  <b-skeleton width="100%"  height="20px" style="margin-bottom:5px"></b-skeleton>
    <b-skeleton width="60%"  height="10px" style="margin-bottom:5px"></b-skeleton>


</b-col>

<b-col lg="12" md="12" sm="12" cols="12" xl="12">

<hr>
</b-col>

<b-col lg="2" md="2" sm="2" cols="2" xl="2">

<b-skeleton type="avatar" width="40px" height="40px"></b-skeleton>



</b-col>

<b-col lg="8" md="8" sm="8" cols="8" xl="8">

  <b-skeleton width="100%"  height="10px" style="margin-bottom:5px"></b-skeleton>
            <b-skeleton width="70%"  height="10px" style="margin-bottom:5px"></b-skeleton>
            <b-skeleton width="50%"  height="10px" style="margin-bottom:5px"></b-skeleton>



</b-col>


<b-col lg="2" md="2" sm="2" cols="2" xl="2">

  <b-skeleton width="100%"  height="20px" style="margin-bottom:5px"></b-skeleton>
    <b-skeleton width="60%"  height="10px" style="margin-bottom:5px"></b-skeleton>


</b-col>

<b-col lg="12" md="12" sm="12" cols="12" xl="12">

<hr>
</b-col>


<b-col lg="2" md="2" sm="2" cols="2" xl="2">

<b-skeleton type="avatar" width="40px" height="40px"></b-skeleton>



</b-col>

<b-col lg="8" md="8" sm="8" cols="8" xl="8">

  <b-skeleton width="100%"  height="10px" style="margin-bottom:5px"></b-skeleton>
            <b-skeleton width="70%"  height="10px" style="margin-bottom:5px"></b-skeleton>
            <b-skeleton width="50%"  height="10px" style="margin-bottom:5px"></b-skeleton>


</b-col>


<b-col lg="2" md="2" sm="2" cols="2" xl="2">

    <b-skeleton width="100%"  height="20px" style="margin-bottom:5px"></b-skeleton>
    <b-skeleton width="60%"  height="10px" style="margin-bottom:5px"></b-skeleton>

</b-col>

       </b-row>
 
       
    
       </b-card>

       <b-card v-else-if="code200">

        <b-row>

        <b-col lg="10" md="10" sm="10" cols="10" xl="10">

          <b-link :to="{ name: 'transacciones'}"  >

<h4 class="font-weight-bolder" style="text-align: left !important;margin-bottom: 20px;">
   Ver mis últimos movimientos <svg class="arrow_more" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 20.633 20.633"><g><path d="M15.621 9.844 5.971.195C5.842.064 5.674.0 5.5.0c-.171.0-.343.064-.473.195L5.014.207c-.126.127-.197.299-.197.475v4.682c0 .178.071.348.197.471l4.481 4.482-4.481 4.479c-.126.126-.197.294-.197.475v4.68c0 .18.071.354.197.475l.013.01c.124.127.294.197.473.197s.348-.07.474-.197l9.647-9.646C15.881 10.531 15.881 10.104 15.621 9.844z"/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/></g><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/></svg>
  </h4>

</b-link>




          </b-col>



          <b-col lg="2" md="2" sm="2" cols="2" xl="2" align="center"  style="text-align: right;">

          <feather-icon
                      icon="RefreshCcwIcon"
                      size="16"
                      @click="refresh"
                      style="cursor:pointer"
                      />

          </b-col>


        </b-row>


        <b-row v-for="transaccion in transactions" :key="transaccion.idTransaction">

          <b-col lg="2" md="2" sm="2" cols="2" xl="2" style="display: flex;">

            <b-avatar
                                      size="35"
                                      :src="transaccion.photo"
                                      variant="light-primary"
                                      style="margin:auto"
                                    ></b-avatar>

          


              </b-col>

              <b-col lg="7" md="7" sm="7" cols="7" xl="7">

                <p class="p_table">{{transaccion.displayName}}</p>
                <p class="p2_table">{{transaccion.source}}</p>
                <p class="p2_table">{{transaccion.dateFormated}}</p>
                <p  style="font-size:12px;    margin-bottom: 5px;text-align:  left;" v-if="transaccion.status === 'Disponible'" >
                                     <b-badge variant="success">
                                        {{transaccion.status}}
                                      </b-badge>
                                  
                                    </p>
                                  <p style=" font-size:12px;   margin-bottom: 5px;text-align:  left;" v-else-if="transaccion.status === 'Pendiente'" >

                                    <b-badge variant="info">
                                        {{transaccion.status}}
                                    </b-badge>


                                  </p>

                                  <p style=" font-size:12px;   margin-bottom: 5px;text-align:  left;" v-else-if="transaccion.status === 'Pendiente de enviar recibo firmado'" >

                                    <b-badge variant="warning">
                                      Pendiente de enviar recibo firmado
                                    </b-badge>


                                    </p>

                                    <p style=" font-size:12px;   margin-bottom: 5px;text-align:  left;" v-else-if="transaccion.status === 'Enviada para revision'" >

                                      <b-badge variant="warning">
                                        Enviada para revisión
                                      </b-badge>


                                      </p>
                                  <p style=" font-size:12px;   margin-bottom: 5px;text-align:  left;" v-else>

                                     <b-badge variant="danger" >
                                        {{transaccion.status}}
                                      </b-badge>
                                   
                                    
                                    </p>

              </b-col>


              <b-col lg="3" md="3" sm="3" cols="3" xl="3" >

                

                <p v-if="transaccion.type === 'Receive Money'"  class="entrada_money">

                
                                    +  ${{transaccion.mount}}
                       
                </p>


                <p v-else class="salida_money">

               
                                  -   ${{transaccion.mount}}
                     
                </p>

                

              </b-col>

              <b-col lg="12" md="12" sm="12" cols="12" xl="12" style="margin-bottom:10px"> </b-col>


        </b-row>

   
         
        <b-row >




          </b-row>

        
       </b-card>

       
       <b-card v-else align="center">

        <b-row>

          <b-col lg="10" md="10" sm="10" cols="10" xl="10">

            <b-link :to="{ name: 'transacciones'}"  >

            <h4 class="font-weight-bolder" style="text-align: left !important; margin-bottom: 20px;">
              Ver mis últimos movimientos <svg class="arrow_more" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 20.633 20.633"><g><path d="M15.621 9.844 5.971.195C5.842.064 5.674.0 5.5.0c-.171.0-.343.064-.473.195L5.014.207c-.126.127-.197.299-.197.475v4.682c0 .178.071.348.197.471l4.481 4.482-4.481 4.479c-.126.126-.197.294-.197.475v4.68c0 .18.071.354.197.475l.013.01c.124.127.294.197.473.197s.348-.07.474-.197l9.647-9.646C15.881 10.531 15.881 10.104 15.621 9.844z"/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/></g><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/></svg>
              </h4>
             
            </b-link>




            </b-col>



            <b-col lg="2" md="2" sm="2" cols="2" xl="2" align="center"></b-col>



          <b-col lg="12" md="12" sm="12" cols="12" xl="12">


                <svg  class="icons_colors" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 480.61 480.61" style="enable-background:new 0 0 480.61 480.61"><g><path d="M469.012 20.343l-2.395-6.339-6.339-2.394C439.878 3.906 418.436.0 396.547.0c-48.104.0-93.33 18.733-127.346 52.749l-48.227 48.227-107.787 5.228-90.214 90.213 77.783 24.777-28.763 28.762 16.802 16.802-37.224 57.056 105.235 105.237 57.057-37.224 16.802 16.802 28.763-28.763 24.775 77.783 90.216-90.214 5.227-107.786 48.227-48.227C477.832 161.462 493.98 86.459 469.012 20.343zM79.029 182.788l47.182-47.181 63.197-3.065-64.773 64.773L79.029 182.788zM160.805 390.623l-70.806-70.806 20.473-31.381 81.713 81.714L160.805 390.623zM345.015 354.412l-47.181 47.181-14.527-45.606 64.773-64.773L345.015 354.412zM230.665 366.202 114.419 249.956 290.414 73.961C318.764 45.612 356.455 30 396.547 30c15.957.0 31.625 2.487 46.671 7.401 17.451 53.48 3.463 112.785-36.558 152.807L230.665 366.202z"/><path d="M364.951 70.67c-12.02.0-23.32 4.681-31.819 13.18-17.546 17.545-17.546 46.094.0 63.64 8.499 8.499 19.8 13.18 31.819 13.18s23.32-4.681 31.819-13.18c17.546-17.545 17.546-46.094.0-63.64C388.272 75.351 376.971 70.67 364.951 70.67zM375.558 126.277c-2.833 2.833-6.6 4.394-10.606 4.394s-7.773-1.561-10.606-4.393c-5.849-5.849-5.849-15.365.0-21.214 2.833-2.833 6.6-4.393 10.606-4.393s7.773 1.56 10.606 4.393C381.406 110.912 381.406 120.429 375.558 126.277z"/><path d="M.013 375.612l33.999-34 21.213 21.213-33.999 33.999L.013 375.612zM105.004 480.61l-21.213-21.213 33.999-33.999 21.213 21.213L105.004 480.61zM25.031 432.878l50.122-50.122 21.213 21.213-50.122 50.122L25.031 432.878z"/></g><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/></svg>

                <p style="text-align: center; font-size:14px; margin-top:15px">
                                No se encontraron transacciones
                              </p>

           </b-col>

                      </b-row>

                
        </b-card>

 

 
  </div>
 
 </template>
 
 <script>
 import {
   BButton,BSkeleton,  BRow, BCol, BCard, BAvatar, BBadge, BLink
 } from 'bootstrap-vue'
 
 
 
 
 export default {
   components: {
    BSkeleton,
     BButton,
     BLink,
     BAvatar,
     BBadge,
     BRow,
     BCol,
     BCard,
   },
   props: ['userId','tokenAuth'],
   data() {
     return {
       code200: false,
       loading: true,
       transactions:[],
     }
   },
   computed: {
 
   },
   watch: {
 
   },
   created(){


   },
   mounted() {


     this.getTransactions();


  
   },
   methods: {
 
     refresh() {
       this.$eventBus.$emit('reiniciarTransaccionesHome')
     }, getTransactions(){
      this.loading = true;
              this.code200 = false;
      
   
 
          this.$https.post('/transactions/transactionsOnly3/', { tokenAuth: this.tokenAuth, userId: this.userId }).then(response => {

          
            if (response.data.code == 200) {

              this.transactions= response.data["transactions"];

              this.loading = false;

              

              this.code200 = true
            } else {


              if (response.data.code == 401) {
          
                    localStorage.removeItem('userData')



                    // Redirect to login page
                    this.$router.push({ name: 'auth-login' })
                    } else {


                        if(response.data.code === 404){
                          this.loading = false;
                          this.code200 = false;

                        }else{
                          this.getTransactions();
                        }
                  
                      }

            }
          }).catch(error => {
              this.getTransactions();
          })

     }
 
   },
 }
 </script>
 
 <style lang="scss">
 

 
 </style>
 