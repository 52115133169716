<template>



    <b-row :key="componentKey">


      <b-col
        lg="6"
        md="6"
        sm="12"
        cols="12"
        xl="3"
      >

       <saldos    :key="componentKeySaldos" :user-id="userId" :token-auth="tokenAuth" />

      </b-col>


      

      <b-col
        lg="6"
        md="6"
        sm="12"
        cols="12"
        xl="9"


      >

 
      <carousel   :autoplay="false" :dots="false" :stagePadding="stagePadding" :nav="false" :items="3" :margin="margin" :responsive="{0:{items:1,nav:false},600:{items:2,nav:false},770:{items:1,nav:false},1200:{items:3,nav:false}}">

<saldo-transito    :user-id="userId"  :is-profile-verificated="isProfileVerificated" :token-auth="tokenAuth" />

<socio   :user-id="userId" :token-auth="tokenAuth" />




<referidos    :key="componentKeyCardReferidos" :user-id="userId" :token-auth="tokenAuth" />


</carousel>



   
      </b-col>


      <b-col
              lg="6"
              md="12"
              sm="12"
              cols="12"
              xl="3"
              order="2"
              order-sm="2"
              order-md="2"
              order-lg="1"
              order-xl="1"

            

            >
            
            <acceso-directos   :user-id="userId" :token-auth="tokenAuth" :address="address" :id-wallet="idWallet" />

            <link-referidos  :promo-code="promoCode" :display-name="displayName" :is-sidebar="false" />
 
            <inversion  :key="componentKeyInversion" :user-id="userId"  :is-profile-verificated="isProfileVerificated" :token-auth="tokenAuth" />

            </b-col>

      <b-col
              lg="6"
              md="12"
              sm="12"
              cols="12"
              xl="3"
              order="3"
              order-sm="3"
              order-md="3"
              order-lg="2"
              order-xl="2"

              v-if="!isMobile"

            

            >
            <transacciones    :key="componentKeyTransacciones" :user-id="userId" :token-auth="tokenAuth" />
        
            </b-col>

            <b-col
              lg="12"
              md="12"
              sm="12"
              cols="12"
              xl="6"
              order="1"
              order-sm="1"
              order-md="1"
              order-lg="3"
              order-xl="3"
            >

           <anuncios />

            </b-col>


      <a v-if="!isMobile" class="float2" v-show="show"  v-b-tooltip.hover.left="'¿Dudas o problemas? Contáctate con servicio al cliente'" href="https://api.whatsapp.com/send?phone=19452074178&text=Hola,%20necesito%20contactar%20con%20soporte" target="_blank"  >

        <div class="float_div">
          <svg width="30" class="my-float" style="margin-top: 14px;" height="30"  viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="currentcolor"><path d="M13.601 2.326A7.854 7.854.0 007.994.0C3.627.0.068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933.0 003.79.965h.004c4.368.0 7.926-3.558 7.93-7.93A7.898 7.898.0 0013.6 2.326zM7.994 14.521a6.573 6.573.0 01-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251A6.56 6.56.0 011.407 7.922c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56.0 014.66 1.931 6.557 6.557.0 011.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729.0 00-.529.247c-.182.198-.691.677-.691 1.654.0.977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/></svg>

                    </div>


        </a>


     </b-row>


</template>

<script>
import { BRow, BCol,BLink, BCard, VBTooltip,VBPopover} from 'bootstrap-vue'


import Saldos from './Saldos.vue'
import Transacciones from './Transacciones.vue'
import SaldoTransito from './SaldoTransito.vue'
import AccesoDirectos from './AccesoDirectos.vue'
import Referidos from './Referidos.vue'
import Inversion from '../pages/inversiones/Inversion.vue'
import carousel from 'vue-owl-carousel'
import Socio from './Socio.vue'
import Anuncios from './Anuncios.vue'
import LinkReferidos from '../pages/others/LinkReferidos.vue'
export default {
  components: {
    BRow,
    Anuncios,
    BCol,
    BCard,
    AccesoDirectos,
    LinkReferidos,
    BLink,
    Saldos,
    Transacciones,
    Socio,
    Referidos,
    carousel,
    Inversion,
    SaldoTransito,
  },
  directives: {
            'b-popover': VBPopover,
      'b-tooltip': VBTooltip,
    },
  data() {

    const datos_json = JSON.parse(this.$userGlobal);

let stagePadding=0;
let margin=10;
    if(this.$isMobile){
      stagePadding=10;
      margin=5;

    }

    return {
      componentKey: 0,
      componentKeyInversion:500,
      idWallet:datos_json.idWalletUSDT,
      address:datos_json.addressUSDT,
      componentKeyEmpezar:100,
      componentKeySaldos:200,
      componentKeyCardReferidos:300,
      componentKeyTransacciones:400,
      stagePadding:stagePadding,
      margin:margin,
      userId: datos_json.userId,
      promoCode: datos_json.promoCode,
      alias: datos_json.alias,
      tokenAuth: this.$tokenGlobal,
      isMobile:this.$isMobile,
      displayName:datos_json.displayName,
      isProfileVerificated: datos_json.isProfileVerificated,
      show:true,
    }
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);

  },
  destroyed() {
    window.removeEventListener("scroll",this.handleScroll);  
  },
  activated() {
   
    this.$eventBus.$emit('primerMenu1')
       this.$eventBus.$emit('primerMenu2')
},
  mounted() {


    this.$eventBus.$on('reiniciarInversion', () => {
    this.componentKeyInversion += 1
  });

      this.$eventBus.$on('reiniciarCardReferidos', () => {
      this.componentKeyCardReferidos += 1
    });


    

        this.$eventBus.$on('reiniciarSaldos', () => {
      this.componentKeySaldos += 1
    });

    this.$eventBus.$on('reiniciarTransaccionesHome', () => {
      this.componentKeyTransacciones += 1
    });

    
    

    

    this.$eventBus.$on('reiniciarHome', () => {
      this.componentKey += 1
    })

    document.title = 'Inicio - EQCoop'



  },
  methods: {

    handleScroll() {




        var currentScrollPosition = window.scrollY

        if(Number(currentScrollPosition) < Number(this.scrollPosition)){
        
          this.show = true

        }
        else{
            this.show = false


        }

        this.scrollPosition = window.scrollY

        }, 





  },
}
</script>

<style lang="scss" >



</style>
