<template>

    
    <b-col cols="12" sm="12" md="12" xl="12" lg="12" class="scroll_div_perfil">
    
    
      <b-link v-if="!hasPin" :to="{ name: 'perfil', hash: '#pin'}" @click="closeModal"><b-card  class="card_opciones"  >
        
        <b-row>
        
    
            <b-col cols="10" sm="10" md="10" lg="10" xl="10" >
    
                <p style="text-align:left;margin-bottom:5px; font-weight: bold;">Registrar código PIN de seguridad</p>
                <p style="text-align:left; margin:0px; font-size:12px">Te brindará seguridad adicional a todas tus transacciones</p>
    
    
            
            </b-col>
    
            <b-col cols="2" sm="2" md="2" lg="2" xl="2" align="center" style="display: flex;" >
    
                <div style="margin:auto">
    
                  <feather-icon
                                              icon="XCircleIcon"
                                              size="20"
                                              style="color:#ff2828"
                                              />
    
                
                </div>
    
    
    
                </b-col>
    
            
        </b-row>
    
        </b-card>
      </b-link>
    
        <b-card v-else class="card_step">
    
          <b-row>
    
    
              <b-col cols="10" sm="10" md="10" lg="10" xl="10" >
    
                      <p style="text-align:left; margin:0px">Código PIN de seguridad registrada</p>
    
              
    
                  
                  </b-col>
    
                  <b-col cols="2" sm="2" md="2" lg="2" xl="2" align="center" >
    
                        <feather-icon
                              icon="CheckCircleIcon"
                              size="20"
                              style="color:#00970f"
                              />
                    
    
    
                      </b-col>
    
                  
              </b-row>
    
          </b-card>
    
    
    
    
    
    
    <b-link v-if="!hasDni" :to="{ name: 'perfil', hash: '#general'}" @click="closeModal">
        
      <b-card  class="card_opciones"    >
        
        <b-row>
        
    
            <b-col cols="10" sm="10" md="10" lg="10" xl="10" >
    
                <p style="text-align:left;margin-bottom:5px; font-weight: bold;">Asociar documento de identidad</p>
                <p style="text-align:left; margin:0px; font-size:12px">Añade tu número de cédula a tu perfil</p>
    
    
            
            </b-col>
    
            <b-col cols="2" sm="2" md="2" lg="2" xl="2" align="center" style="display: flex;" >
    
                <div style="margin:auto">
    
                  <feather-icon
                                              icon="XCircleIcon"
                                              size="20"
                                              style="color:#ff2828"
                                              />
    
                
                </div>
    
    
    
                </b-col>
    
            
        </b-row>
    
        </b-card>
    
      </b-link>
        <b-card v-else class="card_step">
    
          <b-row>
    
    
              <b-col cols="10" sm="10" md="10" lg="10" xl="10" >
    
                      <p style="text-align:left; margin:0px">Documento de identidad registrado</p>
    
              
    
                  
                  </b-col>
    
                  <b-col cols="2" sm="2" md="2" lg="2" xl="2" align="center" >
    
                        <feather-icon
                              icon="CheckCircleIcon"
                              size="20"
                              style="color:#00970f"
                              />
                    
    
    
                      </b-col>
    
                  
              </b-row>
    
          </b-card>
     
          <b-link v-if="!isProfileVerificatedFinal" :to="{ name: 'perfil', hash: '#verificacion'}" @click="closeModal">
       
          <b-card  class="card_opciones"    >
        
        <b-row>
        
    
            <b-col cols="10" sm="10" md="10" lg="10" xl="10" >
    
                <p style="text-align:left;margin-bottom:5px; font-weight: bold;">Verificación de cuenta</p>
                <p style="text-align:left; margin:0px; font-size:12px">Desbloquea todas las funcionalidades de EQCoop</p>
    
    
            
            </b-col>
    
            <b-col cols="2" sm="2" md="2" lg="2" xl="2" align="center" style="display: flex;" >
    
                <div style="margin:auto">
    
                  <feather-icon
                                              icon="XCircleIcon"
                                              size="20"
                                              style="color:#ff2828"
                                              />
    
                
                </div>
    
    
    
                </b-col>
    
            
        </b-row>
    
        </b-card>

        </b-link>
    
    
        <b-card v-else class="card_step">
    
          <b-row>
    
    
              <b-col cols="10" sm="10" md="10" lg="10" xl="10" >
    
                      <p style="text-align:left; margin:0px">Cuenta de usuario verificada</p>
    
              
    
                  
                  </b-col>
    
                  <b-col cols="2" sm="2" md="2" lg="2" xl="2" align="center" >
    
                        <feather-icon
                              icon="CheckCircleIcon"
                              size="20"
                              style="color:#00970f"
                              />
                    
    
    
                      </b-col>
    
                  
              </b-row>
    
          </b-card>
    
        
    
    
    
    </b-col>
          
          </template>
          
          <script>
          import {
            BButton, BRow, BCol, BCard, BLink
          } from 'bootstrap-vue'
          
          
          
          export default {
            components: {
              BButton,
              BLink,
              BRow,
              BCol,
              BCard,
      
            },
            props: [ 'hasPin', 'hasDni','isProfileVerificatedFinal'],
            data() {
          
          
              return {
          
          
              }
            },
            computed: {
          
            },
            watch: {
          
            },
            mounted() {
          
            },
            methods: {
      
        
              closeModal(){
                this.$eventBus.$emit('closeModalPasos')
              }
                  
                
          
            },
          }
          </script>
          
          <style lang="scss">
          
          
          
          </style>
          