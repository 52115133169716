<template>

<div>

    <b-skeleton v-if="loading" width="100%"  height="155px" style="margin-bottom:0px" ></b-skeleton>


    <b-card v-else-if="hasPlan"  class="card_body_saldos" >

       
        <b-row >

            <b-col cols="12" sm="12" md="12" lg="12" xl="12" align="center">

                <p class="saldo_txt" style=" text-align: center;margin:0px; font-size:18px; font-weight: bold;">
                        <feather-icon

                            icon="SmileIcon"
                            size="40"

                            style="color:#fff; margin-right: 10px;"
                            />
                            ¡FELICIDADES!</p>


                </b-col>


     

            <b-col cols="12" sm="12" md="12" lg="12" xl="12" >

            

                    <p style="margin-bottom:5px;line-height: 16px; margin-top:5px; font-size:13px; text-align: center;color:#fff;">Actualmente estás ganando comisiones por las transacciones de tus referidos al ser SOCIO EQCoop</p>

                  

              
              
       

            </b-col>
            <b-col cols="12" sm="12" md="12" lg="12" xl="12" style="padding: 0;">

                            
                
                    <p style="margin-bottom:0px;font-weight: bold; margin-top:5px; font-size:20px; text-align: center;color:#fff;">{{ DateProxPay }}</p>
                    <p style="margin-bottom:0px; margin-top:0px; font-size:11px; text-align: center;color:#fff;">Fecha próxima de pago</p>


           
          

             </b-col>



        </b-row>

       


    

    </b-card>

    <b-card v-else  class="card_body_saldos">


        <b-row >



        <b-col cols="12" sm="12" md="12" lg="12" xl="12" align="center">

            <p class="saldo_txt" style=" text-align: center;margin:0px; font-size:18px; font-weight: bold;">
                    <feather-icon

                        icon="AlertCircleIcon"
                        size="40"

                        style="color:#fff; margin-right: 10px;"
                        />
                        ¡ IMPORTANTE !</p>


        </b-col>




        <b-col cols="12" sm="12" md="12" lg="12" xl="12" >



                <p style="margin-bottom:12px; margin-top:5px; font-size:13px; text-align: center;color:#fff;line-height: 16px;">Actualmente no estás ganando comisiones por las transacciones de tus referidos</p>

            

        
        


        </b-col>
        <b-col cols="12" sm="12" md="12" lg="12" xl="12">


            
                <b-button
            
                variant="outline-secondary"
                block
                size="md"
                :to="{ name: 'marketplaceparam', params: { id: idSocioEqpay }}"
            >

            <b>¡Activa Socio EQCoop! <feather-icon
        
                                                        icon="ExternalLinkIcon"
                                                        size="14"
                                                        style="margin-left: 5px;"
                                                    /> </b>

            </b-button>






        </b-col>

        </b-row>

</b-card>



</div>




</template>

<script>
import {
BButton, BRow, BCol, BCard,BSkeleton
} from 'bootstrap-vue'


export default {
components: {
BButton,
BRow,
BCol,
BCard,
BSkeleton,
},
props: ['userId','tokenAuth'],
directives: {

},
data() {



return {
    loading:true,
    hasPlan:false,
    DateProxPay:"",
    idSocioEqpay:""
}
},
computed: {

},
watch: {



},
created(){





},
activated() {

    this.loadCheck();

},
mounted() {


   

},
methods: {



loadCheck(){

this.loading=true;


        this.$https.post('/subcripcion/checkSocioEQPay/', { tokenAuth: this.tokenAuth, userId: this.userId }).then(response => {
            

         

            if (response.data.code == 200) {
            this.loading = false;

           this.hasPlan=true;
           this.DateProxPay=response.data.DateProxPay;

   
            
            } else {




            if (response.data.code == 401) {

                this.$toast.error(response.data.message, {
                position: 'top-right',
                timeout: 3010,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.4,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: 'button',
                icon: true,
                rtl: false,
                })
                
                localStorage.removeItem('userData')

                

                
                

                

                
                

                // Redirect to login page
                this.$router.push({ name: 'auth-login' })
            } else {

                if (response.data.code == 404) {
                    this.loading=false;
                    this.idSocioEqpay=response.data.idSocioEqpay
                   
                  
                    this.hasPlan=false;

                }else{
                    this.loadCheck();
                }
               
            }
            }
        }).catch(error => {
            this.loadCheck();
        })



},

}
}

</script>

<style lang="scss">







</style>
