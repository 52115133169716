<template>

<div>
  <modal-solicitar v-if="openModalSolicitar" :key="componentKeySolicitar" :type="type"  :user-id="userId" :token-auth="tokenAuth"  />
     

<b-card  class="card_body_saldos" >

  
            <b-row>

                      <b-col cols="1" sm="1" md="1" lg="1" xl="1">

                        
                          <b-skeleton width="10px" v-if="loading" height="10px" type="button"></b-skeleton>

                        

                        <feather-icon
                         v-else
                        icon="RefreshCcwIcon"
                        size="18"
                        @click="refresh"
                        style="cursor:pointer;color:#fff"
                        />


                 </b-col>

        

                  <b-col cols="10" sm="10" md="10" lg="10" xl="10" >

                 


                </b-col>

         



                 <b-col cols="1" sm="1" md="1" lg="1" xl="1">

                 
                     <b-button v-if="isMobile" style="position: absolute;top: 0px;right:     0px;"   variant="light"  @click="dialog = true" > 
                                         <feather-icon
                                          icon="MoreVerticalIcon"
                                          size="25"
                                          tyle="color:#fff"
                                          /> </b-button>


               

                  

                
               


                          <b-dropdown v-else style="position: absolute;top: 0px;;right: 0px;"  dropleft variant="light" size="sm"  no-caret>
                                        <template  #button-content>

                                         <feather-icon
                                          icon="MoreVerticalIcon"
                                          size="25"
                                          tyle="color:#fff"
                                          />
                                          
                                

                                        </template>
                                      
                                        <b-dropdown-item @click="openModalSolicitarDinero('QR')">
                                          <feather-icon
                                            icon="MaximizeIcon"
                                            size="20"
                                            class="icon_menu3"
                                            />
                                            Mi código QR</b-dropdown-item>
                                            <b-dropdown-item @click="openModalSolicitarDinero('Solicitar')">
                                              <feather-icon
                                          icon="HeartIcon"
                                          size="20"
                                          class="icon_menu3"
                                          />
                                            Solicitar pago</b-dropdown-item>

                                        <b-dropdown-item><svg class="icon_menu" width="20" height="20"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 340 340" style="enable-background:new 0 0 340 340"><path d="M296.416 22h-31.163c-.609-12.277-10.788-22-23.214-22H97.792C85.366.0 75.188 9.723 74.578 22H43.415C30.779 22 20.5 32.388 20.5 45.157v59.02c0 8.357 4.067 18.319 9.89 24.227l16.211 16.448C52.489 150.828 62.473 155 70.878 155H74.5v8.025c0 11.73 7.67 26.016 17.431 32.523l49.901 33.16L134.688 272h-32.73c-5.08.0-9.936 3.79-11.295 8.684l-13.346 48.07c-.78 2.81-.28 5.66 1.372 7.833 1.652 2.174 4.272 3.413 7.188 3.413h168.077c2.915.0 5.535-1.214 7.188-3.388 1.652-2.174 2.152-5.016 1.372-7.826l-13.344-48.111c-1.361-4.894-6.217-8.675-11.296-8.675h-32.733l-7.156-43.29 50-33.211c9.761-6.507 17.515-20.743 17.515-32.473V155h3.453c8.431.0 18.431-4.172 24.307-10.134l16.268-16.463c5.872-5.959 9.973-15.921 9.973-24.226v-59.02C319.5 32.388 309.145 22 296.416 22zM74.5 125h-3.49c-.822-.106-2.485-.798-3.143-1.307l-16.017-16.251c-.534-.703-1.254-2.498-1.351-3.371V52h24v73zM221.003 96.939l-22.628 16.559c-3.56 2.586-5.36 8.185-4 12.37l8.641 26.631c1.36 4.185-.44 5.507-4 2.921l-22.626-16.433c-3.56-2.586-9.385-2.583-12.945.003l-22.633 16.443c-3.56 2.586-5.36 1.279-4-2.906l8.644-26.601c1.36-4.185-.44-9.724-4-12.31l-22.63-16.678c-3.56-2.586-2.872-4.939 1.528-4.939h27.97c4.4.0 9.113-3.187 10.473-7.371l8.647-26.484c1.36-4.184 3.585-4.125 4.945.06l8.641 26.394c1.359 4.185 6.072 7.401 10.472 7.401h27.972C223.876 92 224.563 94.353 221.003 96.939zM289.5 104.04c-.115.885-.868 2.685-1.417 3.384l-16.068 16.261c-.67.513-2.357 1.209-3.191 1.315H265.5V52h24V104.04z"/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/></svg>  Cajear mis EQCoins  </b-dropdown-item>

                                     
                                      </b-dropdown>


                </b-col>

                <b-col cols="9" sm="9" md="9" lg="9" xl="9" >
                   
         
 
                    <p v-if="isSaldo" class="saldo_txt">Saldo disponible 
                        <feather-icon
                            icon="HelpCircleIcon"
                            size="16"
                            v-b-popover.hover.bottom.html="'Este saldo puede ser usado para envios interbilleteras, pagos de servicios o ser retirados hacia su cuenta bancaria.'"
                            title="Saldo de billetera"
                            style="margin-right: 5px;cursor:pointer;"
                            /> </p>



                        <p v-if="isEQPoints" class="saldo_txt">EQCoins
                    <feather-icon
                        icon="HelpCircleIcon"
                        size="16"
                        v-b-popover.hover.bottom.html="'Los EQCoins son puntos ganados por utilizar las funcionalidades de EQCoop. Puedes canjear estos puntos por premios.'"
                        title="Cantidad de EQCoins"
                        style="margin-right: 5px;cursor:pointer;"
                        /> </p>

                      <p v-if="isCriptos" class="saldo_txt">Saldo disponible 
                      <feather-icon
                        icon="HelpCircleIcon"
                        size="16"
                        v-b-popover.hover.bottom.html="'Este saldo corresponde al balance que tiene en sus billeteras de criptomonedas.'"
                        title="Saldo de criptomoneda"
                        style="margin-right: 5px;cursor:pointer;"
                        /> </p>

                 
                        <b-skeleton v-if="loading" width="100%" height="35px" style="margin:0px" ></b-skeleton>
                       

                     

                      <p  v-if="code200" class="saldo2_txt">{{balance}}<small class="currency">{{currency}}</small> </p>
              

                </b-col>

                 <b-col cols="3" sm="3" md="3" lg="3" xl="3" style="padding: 0;">

                  <b-skeleton v-if="loading" width="100%"  height="35px" style="margin-bottom:0px;margin-top: 15px;" ></b-skeleton>
                       
          
                       
                  <b-form-select
                  v-else
                        v-model="optionSaldo"
                        :options="options"
                        @change="onChange($event)"
                        style="margin-top: 15px;"
                        size="sm"
                        
                        />


                 </b-col>

                 <b-col cols="12" sm="12" md="12" lg="12" xl="12" style="margin-top:4px">

                        
                                                  
                    <b-button

                    variant="outline-secondary"
                    block
                    size="md"
                    :to="{ name: 'transacciones' }"
                    >

                    <b>Ver mis transacciones <feather-icon

                                                            icon="ExternalLinkIcon"
                                                            size="14"
                                                            style="margin-left: 5px;"
                                                          /> </b>

                    </b-button>






                    </b-col>



            </b-row>



               <vue-bottom-dialog v-if="dialog" :indentColor="indentColor"  :topRadius="radius" v-model="dialog" :height="210"  :overlayColor="overlay">

                  
                    <b-row style="font-size: 15px;padding: 10px;">

                      <b-col cols="12" sm="12" md="12" lg="12" xl="12"> 

                        <h4 style="margin:0"  class="font-weight-bolder">
                          Otras opciones
                          <hr>

                        </h4>

                        </b-col>



                        <b-link @click="openModalSolicitarDinero('QR')" sm="12" md="12" lg="12" xl="12"   class="item_menu_bottom">
                          <feather-icon
                          icon="MaximizeIcon"
                          size="20"
                          class="icon_menu3"
                          />
                          Mi código QR</b-link>
                          
                          <b-link @click="openModalSolicitarDinero('Solicitar')" sm="12" md="12" lg="12" xl="12"   class="item_menu_bottom">
                          <feather-icon
                          icon="HeartIcon"
                          size="20"
                          class="icon_menu3"
                          />
                         Solicitar pago</b-link>
                          

                     
                          <b-link sm="12" md="12" lg="12" xl="12"  class="item_menu_bottom"> <svg class="icon_menu2" width="20" height="20"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 340 340" style="enable-background:new 0 0 340 340"><path d="M296.416 22h-31.163c-.609-12.277-10.788-22-23.214-22H97.792C85.366.0 75.188 9.723 74.578 22H43.415C30.779 22 20.5 32.388 20.5 45.157v59.02c0 8.357 4.067 18.319 9.89 24.227l16.211 16.448C52.489 150.828 62.473 155 70.878 155H74.5v8.025c0 11.73 7.67 26.016 17.431 32.523l49.901 33.16L134.688 272h-32.73c-5.08.0-9.936 3.79-11.295 8.684l-13.346 48.07c-.78 2.81-.28 5.66 1.372 7.833 1.652 2.174 4.272 3.413 7.188 3.413h168.077c2.915.0 5.535-1.214 7.188-3.388 1.652-2.174 2.152-5.016 1.372-7.826l-13.344-48.111c-1.361-4.894-6.217-8.675-11.296-8.675h-32.733l-7.156-43.29 50-33.211c9.761-6.507 17.515-20.743 17.515-32.473V155h3.453c8.431.0 18.431-4.172 24.307-10.134l16.268-16.463c5.872-5.959 9.973-15.921 9.973-24.226v-59.02C319.5 32.388 309.145 22 296.416 22zM74.5 125h-3.49c-.822-.106-2.485-.798-3.143-1.307l-16.017-16.251c-.534-.703-1.254-2.498-1.351-3.371V52h24v73zM221.003 96.939l-22.628 16.559c-3.56 2.586-5.36 8.185-4 12.37l8.641 26.631c1.36 4.185-.44 5.507-4 2.921l-22.626-16.433c-3.56-2.586-9.385-2.583-12.945.003l-22.633 16.443c-3.56 2.586-5.36 1.279-4-2.906l8.644-26.601c1.36-4.185-.44-9.724-4-12.31l-22.63-16.678c-3.56-2.586-2.872-4.939 1.528-4.939h27.97c4.4.0 9.113-3.187 10.473-7.371l8.647-26.484c1.36-4.184 3.585-4.125 4.945.06l8.641 26.394c1.359 4.185 6.072 7.401 10.472 7.401h27.972C223.876 92 224.563 94.353 221.003 96.939zM289.5 104.04c-.115.885-.868 2.685-1.417 3.384l-16.068 16.261c-.67.513-2.357 1.209-3.191 1.315H265.5V52h24V104.04z"/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/></svg> Cajear EQCoins por premios</b-link>
                          

                   


              
                    </b-row>

                   


                </vue-bottom-dialog>

      


              </b-card>



            </div>



</template>

<script>
import {
  BButton,BLink,BFormSelect, BRow, BCol, BCard,BSkeleton,  VBTooltip,VBPopover,BDropdown,BDropdownItem,
} from 'bootstrap-vue'

import ModalSolicitar from '../pages/transferencias/ModalSolicitar.vue'

export default {
  components: {
    BButton,
    BDropdown,BDropdownItem,
    ModalSolicitar,
    BRow,
    BCol,
    BLink,
    BCard,
    BFormSelect,
    BSkeleton,
  },
  props: ['userId','tokenAuth'],
  directives: {
            'b-popover': VBPopover,
      'b-tooltip': VBTooltip,
    },
  data() {



    return {
      code200: false,
      error:false,
      loading: true,
      type:"",
      openModalSolicitar:false,
      componentKeySolicitar:0,
      balance: 0,
      dialog:false,
      color: "#242526",
      overlay:"#000000e6",
      radius:"15px",
      nameRoute: 'transacciones',
      indentColor:"#b0b3b8",
      isSaldo:true,
      isEQPoints:false,
      isCriptos:false,
      isMobile: this.$isMobile,
       currency:"USD",
       body: document.getElementsByTagName('body')[0],
      optionSaldo: "USD",
      options: [
        { value: "USD", text: 'USD' },
        { value: 'USDT', text: 'USDT' },
        { value: 'BSC', text: 'BNB' },
      ],
    }
  },
  computed: {

  },
  watch: {

   
    dialog(newValue) {

    
        if(newValue){
          this.body.classList.add("overflow-hidden");
        }else{
          this.body.classList.remove("overflow-hidden");
        }



        },

  },
  created(){


  


  },
  mounted() {

    this.getSaldo();

  

    this.$eventBus.$on('reiniciarSolicitar', () => {
                        this.componentKeySolicitar += 1
                        this.openModalSolicitar = false;
                        });

    this.$eventBus.$on('reiniciarRecargarTarjetaHome2', () => {

      this.loading=true;
         this.code200 = false;

      this.getSaldo();
    });
    
  

    this.$eventBus.$on('reiniciarEnviar2', () => {

      this.loading=true;
         this.code200 = false;
         
      this.getSaldo();
    });



    this.$eventBus.$on('reiniciarRetiro2', () => {


      this.loading=true;
        this.code200 = false;
        
      this.getSaldo();
      });

  },
  methods: {


  

     openModalSolicitarDinero(opcion){
      this.dialog=false;
 

        this.type= opcion;

             
           this.openModalSolicitar = true;



     },

   


    onChange(opcion){




    switch(opcion) {
        case "USD":
          this.nameRoute="transacciones";
             this.loading=true;
         this.code200 = false;
         this.isSaldo=true;
                this.isEQPoints=false;
                this.isCriptos=false;
         this.getSaldo();


        break;

        case "EQCoins":
        this.nameRoute="transacciones";
         this.loading=true;
         this.code200 = false;
         this.isSaldo=false;
         this.isEQPoints=true;
         this.isCriptos=false;

         this.getEQPoints();


        break;

        default:
        this.nameRoute="wallets";
         this.loading=true;
         this.code200 = false;
         this.isSaldo=false;
         this.isEQPoints=false;
         this.isCriptos=true;


         this.getCriptos();


        break;

    }

      

    },

    refresh() {

    switch(this.optionSaldo) {
        case "USD":
         this.loading=true;
         this.code200 = false;
         this.isSaldo=true;
         this.isEQPoints=false;
         
         this.isCriptos=false;
         this.getSaldo();


        break;

        case "EQCoins":
         this.loading=true;
         this.code200 = false;
         this.isSaldo=false;
         this.isEQPoints=true;
         this.isCriptos=false;

         this.getEQPoints();


        break;

         default:
         this.loading=true;
         this.code200 = false;
         this.isSaldo=false;
         this.isEQPoints=false;
         this.isCriptos=true;

         this.getCriptos();


        break;

    }
      

    },getCriptos(){

      
      this.loading = true

      this.code200 = false;

      if(this.optionSaldo === "BSC"){

        this.currency="BNB"

      }else{
        this.currency=this.optionSaldo;
      }

       this.$httpsCryptos.post('/main/getBalanceOneCripto/', { tokenAuth: this.tokenAuth, userId: this.userId, currency:this.optionSaldo }).then(response => {


          if (response.data.code == 200) {

             this.loading = false

              this.code200 = true;
        
              this.balance= response.data.availableBalance
    
  
          } else {

            this.getCriptos();
          }
        }).catch(error => {
            this.getCriptos();
        })


    } ,getEQPoints(){

      this.loading = true
      this.currency="EQCoins"
      this.code200 = false;

 
    this.$https.post('/profile/getEQPoints/', { tokenAuth: this.tokenAuth, userId: this.userId }).then(response => {
        
       
      if (response.data.code == 200) {
        this.loading = false

        this.code200 = true;
        this.balance= response.data.EQPoints
      } else {

        this.getEQPoints();
      }
    }).catch(error => {
         this.getEQPoints();
    })



    },getSaldo(){

 
        this.currency="USD"
    this.$https.post('/profile/getBalance/', { tokenAuth: this.tokenAuth, userId: this.userId }).then(response => {
        

       
      if (response.data.code == 200) {
        this.loading = false;


          this.code200 = true;
          this.balance= response.data.balance;
       
      } else {

        


        if (response.data.code == 401) {

          this.$toast.error(response.data.message, {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })
          
          localStorage.removeItem('userData')


          // Redirect to login page
          this.$router.push({ name: 'auth-login' })
        } else {
           this.getSaldo();
        }
      }
    }).catch(error => {
         this.getSaldo();
    })
    }

  },
}
</script>

<style lang="scss">







</style>
