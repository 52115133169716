<template>

    <div>
    
    
    
      <b-card v-if="isProfileVerificatedFinal" class="card_body_saldos " >
    
    

      <b-row>

        <b-col cols="1" sm="1" md="1" lg="1" xl="1">

                                  
          <b-skeleton width="10px" v-if="loading" height="10px" type="button"></b-skeleton>



          <feather-icon
          v-else
          icon="RefreshCcwIcon"
          size="18"
          @click="refresh"
          style="cursor:pointer;color:#fff"
          />


          </b-col>



          <b-col cols="11" sm="11" md="11" lg="11" xl="11" ></b-col>

          <b-col cols="8" sm="8" md="8" lg="8" xl="8" >
                 

                   <p  class="saldo_txt">Saldo en tránsito 
                       <feather-icon
                           icon="HelpCircleIcon"
                           size="16"
                           v-b-popover.hover.bottom.html="'Este saldo corresponde al dinero recibido por pagos con tarjeta hacia su cuenta usando cualquier link de cobro o pagos realizados con sus tarjetas en recargas de billetera. <br><br>Para que su pago sea procesado, deberá enviar el comprobante del pago firmado por usted. Esto lo puedes realizar desde el listado de transacciones. <br><br>Una vez que nuestro sistema antrfraude verifique la procedencia de estos pagos, este dinero será acreditado hacia su saldo disponible.'"
                           title="Saldo en tránsito"
                           style="margin-right: 5px;cursor:pointer;"
                           /> </p>



                 

                
                       <b-skeleton v-if="loading" width="50%" height="35px" style="margin:0px" ></b-skeleton>
                      

                    

                     <p  v-else class="saldo2_txt">{{balance}}<small class="currency">USD</small></p>
                

               </b-col>

               <b-col cols="4" sm="4" md="4" lg="4" xl="4" style="padding: 0;display:flex" >
     
                 <svg style="position: absolute;right: 0;top: -25px;margin:auto; fill:#fff; width: 75px;opacity: 0.5;" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"  viewBox="0 0 37.687 37.687"><g><path d="M36.577 21.175c0 9.104-7.407 16.512-16.512 16.512-9.078.0-16.466-7.364-16.509-16.434H1.109l3.67-5.505 3.668 5.505H6.309c.043 7.551 6.195 13.682 13.757 13.682 7.586.0 13.76-6.172 13.76-13.76s-6.174-13.76-13.76-13.76c-.762.0-1.377-.616-1.377-1.376s.615-1.376 1.377-1.376C29.17 4.662 36.577 12.071 36.577 21.175zM20.066 31.419c-5.647.0-10.244-4.597-10.244-10.244.0-.254-.205-.459-.459-.459-.252.0-.457.205-.457.459.0 6.153 5.006 11.162 11.16 11.162s11.16-5.009 11.16-11.162c0-6.155-5.006-11.163-11.16-11.163-.254.0-.459.206-.459.459.0.254.205.459.459.459 5.648.0 10.244 4.596 10.244 10.245C30.311 26.824 25.715 31.419 20.066 31.419zm-5.297-3.55c.27.271.621.404.975.404.353.0.703-.137.973-.404l4.322-4.321c.26-.258.404-.608.404-.974V14.79c0-.76-.617-1.376-1.377-1.376s-1.375.616-1.375 1.376v7.214l-3.922 3.92C14.232 26.462 14.232 27.333 14.769 27.869zM16.681 8.138c.63.532.947 1.508.947 2.924.0 1.526-.344 2.597-1.039 3.211-.688.614-1.898.925-3.625.925l-.502.01v1.615h-1.643v-1.615l-.465-.01c-1.719.0-2.928-.296-3.626-.885-.696-.589-1.049-1.611-1.049-3.068l.013-.492H8.13v.253c0 .887.141 1.46.421 1.719.284.255.924.385 1.906.385l.361.012V9.21c-1.922.0-3.231-.28-3.938-.835C6.183 7.822 5.829 6.781 5.829 5.256c0-1.479.354-2.491 1.057-3.036.707-.543 2.016-.818 3.932-.818V0h1.642v1.403c1.825.0 3.077.261 3.745.782.676.524 1.012 1.493 1.012 2.912v.33H14.86l-.008-.26c0-1.071-.671-1.605-2.007-1.605H12.46v3.612l.542.018C14.818 7.289 16.044 7.606 16.681 8.138zM10.822 3.549 10.468 3.56c-1.444.0-2.165.571-2.165 1.715.0 1.199.715 1.796 2.157 1.796.015.0.132.009.36.021V3.549H10.822zm4.337 7.556c0-.738-.172-1.222-.521-1.456-.348-.233-1.073-.35-2.178-.35v3.822h.354C14.377 13.12 15.159 12.448 15.159 11.105z"/></g></svg>

            


                </b-col>

                <b-col cols="12" sm="12" md="12" lg="12" xl="12" style="margin-top:4px">

                        
                              
                  <b-button

                  variant="outline-secondary"
                  block
                  size="md"
                  :to="{ name: 'transacciones' }"
                  >

                  <b>Ver mis transacciones <feather-icon

                                                          icon="ExternalLinkIcon"
                                                          size="14"
                                                          style="margin-left: 5px;"
                                                        /> </b>

                  </b-button>






                  </b-col>
    
            
    
      </b-row>
    

    
    </b-card>
    
    
    
    <b-card v-else class="card_body_saldos" >
    
    
      <b-row>
    
        <b-col  cols="12" sm="12" md="12" lg="12" xl="12" align="center">

          <feather-icon

              icon="SettingsIcon"
              size="40"
              
              style="margin-left: 5px;color:#fff"
              />
    
          <p style="text-align:center; font-weight: bold;color:#fff; font-size:14px; margin-bottom:0px">Configure su cuenta de EQCoop</p>
          <b-skeleton v-if="loading" width="50%" height="30px" style="margin-bottom:0px !important" ></b-skeleton>
           <p v-else class="p2_opciones" style="color:#fff;text-align: center;font-size:12px; margin-bottom:0px; margin-top:0px">{{ this.completado }} de {{ 3 }} pasos completados </p>  
    
    
        </b-col>
    
      
    
        
        <b-col cols="12" sm="12" md="12" lg="12" xl="12" align="center" style="margin-top:4px">
    
          <b-skeleton v-if="loading" width="100%"  height="36px" style="margin-top:5px; margin-bottom: 0px;"></b-skeleton>
    
    
          <b-button
          v-else
    block
            style="margin-top:3px; margin-bottom:0px"
            size="md"
                            
            @click="modalPasos"
            
                                variant="outline-secondary"
                         
                            >
                            <b>Terminar mi configuración <feather-icon

              icon="ExternalLinkIcon"
              size="14"
              style="margin-left: 5px;"
              /> </b>

                           
    
                            </b-button>
    
          </b-col>
    
    
    
    
      </b-row>
    
    
           
    <b-modal
    ref="modalStep"
                    centered
                    hide-footer
                    hideHeaderClose
                    hideHeader
                    size="sm"
                    @hidden="closeModalStep"
          >
            <div class="d-block text-center">
    
              <b-row>
                <b-col cols="12" sm="12" md="12" xl="12" lg="12">
    
                    <b-avatar
                                    size="70"
                                    variant="light-primary"
                                    >
    
                                    <span class="d-flex align-items-center">
                                    
                                      <p class="circulo_pasos">{{ completado }}/3</p>
    
                                    </span>
    
                      </b-avatar>
    
                    </b-col>
    
    
                    <b-col cols="12" sm="12" md="12" xl="12" lg="12">
    
                    <p style="font-size:18px; text-align: center;margin-bottom:5px; margin-top:10px">Complete la configuración de su cuenta</p>
                    <p style="font-size:12px; text-align: center;margin-bottom:10px; margin-top:0px">{{ completado }} de 3 pasos completados</p>
    
                    <p style="font-size:14px; text-align: center;margin-bottom:15px; margin-top:0px">Haga que sea más seguro y fácil pagar, recibir pagos y comprar con su cuenta.</p>
    
    
    
                    </b-col>
                    <step v-if="showSteps" :has-pin="hasPin" :has-dni="hasDni" :is-profile-verificated-final="isProfileVerificatedFinal"  />
    
              </b-row>
           
            
            </div>
      
          </b-modal>
    
    
         
    
    
    </b-card>
    
    
    
    </div>
    
        
    
    
    </template>
    
    <script>
    import {
    BButton,BLink,BFormSelect,VBTooltip, VBPopover, BRow, BCol, BCard,BSkeleton, BAvatar, BAlert
    } from 'bootstrap-vue'
   

    import Vue from 'vue'
    import Step from './Step.vue'
    
    export default {
    components: {
    BButton,
    Step,
    BAlert,
    BRow,
    BCol,
    BLink,
    BCard,
    VBPopover,
    BAvatar,
    BFormSelect,
    BSkeleton,
    },
    directives: {
    'b-popover': VBPopover,
    'b-tooltip': VBTooltip,
    },
    props: ['userId','tokenAuth','isProfileVerificated'],
    data() {
    
          

    return {
      loading: true,
      showSteps:false,
      isProfileVerificatedFinal: this.isProfileVerificated,
      completado:0,
      balance: "0.00",


       hasDni:false,
       hasPin:false,
  
  
    }
    },
    computed: {
    
    },
    watch: {
    

    
    },
    created(){
    
    

    
    
    
    },
    mounted() {

      if(this.isProfileVerificated){
    
        
    this.getBalanceInTransit();
    
    
   
    
      }

      this.$eventBus.$on('closeModalPasos', () => {
        this.$refs.modalStep.hide();
    
      });


    
 
    
    
    },
    activated() {

      if(!this.isProfileVerificatedFinal){

 
        this.loadEmpezar();



      }
   

},
    methods: {

      refresh() {
        this.getBalanceInTransit();
      },
    
    
      closeModalStep(){
    
    this.showSteps=false;
    
    },
    
      modalPasos(){
        this.showSteps=true;
        this.$refs.modalStep.show();
    
    
      },
      
      getBalanceInTransit(){
        
        this.loading = true;
     

    this.$https.post('/profile/getBalanceInTransit/', { tokenAuth: this.tokenAuth, userId: this.userId }).then(response => {
        

       
      if (response.data.code == 200) {
        this.loading = false;


    
          this.balance= response.data.balance;
       
      } else {

      


        if (response.data.code == 401) {

          this.$toast.error(response.data.message, {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })
          
          localStorage.removeItem('userData')


          // Redirect to login page
          this.$router.push({ name: 'auth-login' })
        } else {
           this.getBalanceInTransit();
        }
      }
    }).catch(error => {
         this.getBalanceInTransit();
    })
    
    },

    loadEmpezar(){

this.loading=true;


this.$https.post('/profile/checkProfileComplete/', { tokenAuth: this.tokenAuth, userId: this.userId }).then(response => {

if (response.data.code == 200) {



this.isProfileVerificatedFinal= response.data.isProfileVerificated;
this.hasDni= response.data.dni;
this.hasPin= response.data.pinSecurity;

this.completado= response.data.completado;

 const datos_json = JSON.parse(this.$userGlobal);



datos_json.isProfileVerificated =response.data.isProfileVerificated

Vue.prototype.$userGlobal= JSON.stringify(datos_json);


localStorage.setItem('userData', JSON.stringify(datos_json))
this.loading=false;


} else {




if (response.data.code == 401) {

this.$toast.error(response.data.message, {
 position: 'top-right',
 timeout: 3010,
 closeOnClick: true,
 pauseOnFocusLoss: false,
 pauseOnHover: true,
 draggable: true,
 draggablePercent: 0.4,
 showCloseButtonOnHover: false,
 hideProgressBar: true,
 closeButton: 'button',
 icon: true,
 rtl: false,
})


localStorage.removeItem('userData')



// Redirect to login page
this.$router.push({ name: 'auth-login' })
} else {
this.loadEmpezar();
}
}
}).catch(error => {

this.loadEmpezar();
})

},
    
}
    }
    
    </script>
    
    <style lang="scss">
    
    
    
    
    
    
    
    </style>
    