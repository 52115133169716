<template>

  <div>




  <b-modal
      ref="modalSolicitar"
      centered
      hide-footer
      :title="title"
      @hidden="closeModalSolicitar"
      size="sm"
    >
      <div class="d-block text-center">
<b-card style="margin-bottom:0px">

                  <b-row>

        

                     <b-col style="margin-bottom: 10px;" align="center" cols="12" sm="12" md="12" lg="12" xl="12"> 

                      <b-alert
                                variant="primary"
                                show
                              >
                                <div class="alert-body">
                            

                                        
                                          <p style="text-align: left;font-size:13px; margin-bottom:0px"> Este es tu código QR, muestráselos a tus contactos para que recibas pagos instantáneos. </p>

                            
                                </div>
                              </b-alert>

                      </b-col>

        


                         <b-col v-if="hideQR" align="center" cols="12" sm="12" md="12" lg="12" xl="12"> 

                               <b-skeleton-img   no-aspect height="200px" width="60%" animation="fade"></b-skeleton-img>


                        </b-col>


                          <b-col align="center" cols="12" sm="12" md="12" lg="12" xl="12"> 

                                 <vue-qr  style="width: 200px !important;" :callback="getDataURL" :margin="margin" logoBackgroundColor="#fff" backgroundColor="#f0f2f5"  :logoScale="logoScale" :logoMargin="logoMargin" :logoCornerRadius="logoCornerRadius" :text="scanData" :correctLevel="correctLevel" :size="size"></vue-qr>
                                



                          </b-col>

                          <b-col  cols="12" sm="12" md="12" lg="12" xl="12" align="center" >

                            <hr>

                            </b-col>

                          <b-col v-if="hideQR" cols="6" sm="6" md="6" lg="6" xl="6" align="center" >

                            <b-skeleton height="30px"></b-skeleton>


                            </b-col>
                            <b-col v-else cols="6" sm="6" md="6" lg="6" xl="6" align="center" >

                              <b-link @click="descargar()" class="icon_dashboard"> 


                                      <b-avatar
                                      size="40"
                                      variant="light-primary"
                                      style="cursor:pointer;">

                                      <span class="d-flex align-items-center">
                                        <feather-icon
                                        icon="DownloadIcon"
                                        size="21"
                                      />

                                      </span>

                                      </b-avatar>

                                 

                                      </b-link>

                             

                              </b-col>

                         

        


                          <b-col v-if="hideQR" cols="6" sm="6" md="6" lg="6" xl="6" align="center" >

                                 <b-skeleton height="30px"></b-skeleton>

                        

                              </b-col>

                              <b-col v-else cols="6" sm="6" md="6" lg="6" xl="6" align="center" >

                                <b-link @click="shared()" class="icon_dashboard"> 


                                      <b-avatar
                                      size="40"
                                      variant="light-primary"
                                      style="cursor:pointer;">

                                      <span class="d-flex align-items-center">
                                        <feather-icon
                                        icon="Share2Icon"
                                        size="21"
                                      />

                                      </span>

                                      </b-avatar>

                                  

                                      </b-link>


                                </b-col>


                </b-row>
       
              </b-card>

      </div>

    </b-modal>


</div>

</template>

<script>
import {
  VBTooltip,VBPopover, BButton, BRow, BCol, BCard, BSkeletonImg,BSkeleton, BAvatar, BLink, BAlert
} from 'bootstrap-vue'
import VueQr from 'vue-qr'


export default {
  components: {
    BButton,
    BLink,
    BAvatar,
    BSkeleton,
    BSkeletonImg,
    BAlert,
    VueQr,
    BRow,
    BCol,
    BCard,
  },
    directives: {
          'b-popover': VBPopover,
    'b-tooltip': VBTooltip,
  },
  props: ['userId','tokenAuth', 'type'],
  data() {


      const datos_json = JSON.parse(this.$userGlobal);

      let scanData="";


      if(datos_json.alias === ""){

        if(datos_json.phone === ""){
          scanData= datos_json.email+";interbilletera";
        }else{
          scanData= datos_json.phone+";interbilletera";
        }


      }else{
        scanData= datos_json.alias+";interbilletera";
      }

 
           

    return {
      size:800,
      margin:50,
      logoScale:0.3,
            logoMargin: 1,
            logoCornerRadius:100,
      correctLevel:3,
        dataUrl:"",
     hideQR:true,
     title:"",
     isMobile:this.$isMobile,
        dialog:false,
        color: "#242526",
      overlay:"#000000e6",
      radius:"15px",
       indentColor:"#b0b3b8",
       scanData:scanData,
       source:"",
       body: document.getElementsByTagName('body')[0],
    }
  },
  computed: {



  },
  watch: {

   



  },
  mounted() {


    
  this.source = this.type;

if(this.source === "QR"){



  this.title="Solicitar dinero";
  this.$refs.modalSolicitar.show();




}else{

  this.$toast.error("Funcionalidad no disponible por el momento", {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          });
          this.$eventBus.$emit('reiniciarSolicitar')

}



  },
  methods: {

    closeModalSolicitar(){
  
  this.$eventBus.$emit('reiniciarSolicitar')

},


    getDataURL(dataUrl,id){

  

      this.dataUrl= dataUrl;
      this.hideQR=false;
      

    },

    descargar(){

      var link = document.createElement("a");
      link.download = "Mi código QR";
      link.href =this.dataUrl;
      link.click();

       

    },

    shared(){


    
      fetch(this.dataUrl).then(function (response) {
        return response.blob()
      }).then(function (blob) {
        let file = new File([blob], "QR.jpg", {type: 'image/jpeg'});
        let filesArray = [file];
        if (navigator.canShare && navigator.canShare({files: filesArray})) {
          navigator.share({
            files: filesArray
          }).then(() => console.log('Successful share'))
            .catch((error) =>   this.$toast.error("Ocurrió un error inesperado", {
              position: 'top-right',
              timeout: 3010,
              closeOnClick: true,
              pauseOnFocusLoss: false,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.4,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: 'button',
              icon: true,
              rtl: false,
            }));
        }else{

            this.$toast.error("Lo sentimos, tu navegador no es compatible para compartir este contenido", {
              position: 'top-right',
              timeout: 3010,
              closeOnClick: true,
              pauseOnFocusLoss: false,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.4,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: 'button',
              icon: true,
              rtl: false,
            })

        }
      });


    

    }

   
  }
}
</script>

<style lang="scss">





</style>
