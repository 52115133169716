<template>

<b-modal
                ref="modalDetallesInversion"
                centered
                ok-only
                 ok-title="Entendido"
                hideHeaderClose
                hideHeader
                size="sm"
                @hidden="closeDetalles"
              >
                <div class="d-block text-center">
          
                  <b-row>

                      <b-col cols="12" sm="12" md="12" lg="12" xl="12" align="center">

                          <b-avatar variant="light" :src="icon" class="border_ahorro" />

                      </b-col>

                      <b-col cols="12" sm="12" md="12" lg="12" xl="12">

                          <p style="font-weight: bold; font-size:16px; text-align: center; margin-top:5px;margin-bottom:10px">Fondo {{optionFondos}}</p>

                          </b-col>

                          <b-col sm="12" md="12" lg="12" xl="12" style="margin-bottom: 10px;">

                                                <b-alert
                                            variant="secondary"
                                            show
                                            style="margin-top:10px;margin-bottom: 10px;"
                                          >
                                            <div class="alert-body">
                                              <p class="saldoDisponible">{{balance}}<small class="currency2">USDT</small></p>
                                              <p style="font-size: 12px;    margin: 0;">Dinero utilizado en este fondo</p>

                                            
                                            </div>
                                          </b-alert>

                                          

                                            </b-col>
                                  <b-col cols="6" sm="6" md="6" lg="6" xl="6" align="center">
                                      <b-card style="margin-bottom:0px">
                                          <img src="/img/dateInicial.svg" style="width: 35px;"/>

                                      <p style="text-align: center; margin-bottom:0px; margin-top:10px">{{DateTransaction}}</p>
                                      <p style="text-align: center; margin:0px; font-size:11px; font-weight: bold;">Fecha inicio</p>
                                  </b-card>
                                  </b-col>

                                  <b-col cols="6" sm="6" md="6" lg="6" xl="6">
                                      <b-card style="margin-bottom:0px" align="center">
                                          <img src="/img/dateFinal.svg" style="width: 35px;"/>
                                      <p style="text-align: center; margin-bottom:0px; margin-top:10px">{{DateExpiration}}</p>
                                      <p style="text-align: center; margin:0px; font-size:11px; font-weight: bold;">Fecha fin</p>
                                  </b-card>
                                  </b-col>


                                  <b-col sm="12" md="12" lg="12" xl="12" style="margin-top:10px">

                                      <b-card>

                                        <app-timeline>
                                          <app-timeline-item
                                            title="Tiempo restante del fondo"
                                            :subtitle="timeRestante"
                                            icon="ClockIcon"
                                            time=""
                                            variant="success"
                                          />

                                          <app-timeline-item
                                            title="Ganancias obtenidas por el momento"
                                            :subtitle="gananciasObtenidas"
                                            icon="DollarSignIcon"
                                            time=""
                                            variant="info"
                                          />

                                        </app-timeline>


                                      </b-card>
                                  
                                  
                                  </b-col>

                                  
                              


                      </b-row>
                      

                           
                         

                            </div>

              </b-modal>


   
  
  </template>
  
  <script>
  import {
    BButton, BRow, BCol, BCard, BSkeletonImg,BSkeleton, BAvatar, BAlert
  } from 'bootstrap-vue'

  import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'


  export default {
    components: {
      BButton,
      
      BAlert,
      AppTimeline,
      AppTimelineItem,
      BAvatar,
      BSkeleton,
      BSkeletonImg,
      BRow,
      BCol,
      BCard,
    },
    props: ['userId','tokenAuth','optionFondos', 'timeDays', 'rendimiento', 'balance', 'DateTransaction','DateExpiration'],
    data() {

      let icon="";

      switch (this.optionFondos) {
            case "Oportunidad":
              icon="/img/oportunidad.svg";           
              break
            case "Rentable":
             icon= "/img/rentable.svg";            
              break
            case "Acumulativo":

                 icon="/img/acumulativo.svg" ;         
  
              break

              case "Productivo":

           icon="/img/productivo.svg";
            break

          }
  
       
          let porcentaje=Number(this.rendimiento)/Number("100");

let dineroMensualRecibir= Number(Number(this.balance)  * Number(porcentaje)).toFixed(2);

let dineroDiarioRecibir=  Number(Number(dineroMensualRecibir) / Number("30")).toFixed(2);



let dineroTotalRecibir= Number(Number(dineroDiarioRecibir) * Number(this.timeDays)).toFixed(2);//esta es lo que recibe en 3 meses

     
  
      return {



       icon:icon,
       loading:false,
       days:0,
       dineroTotalRecibir:dineroTotalRecibir,
       ganancias:0,
       timeRestante: "--- /"+ this.timeDays + " días",
       gananciasObtenidas: "$ --- /"+ dineroTotalRecibir + " USDT"
      
      }
    },
    computed: {
  
  
  
    },
    watch: {
  
 
  
  
  
    },
    mounted() {
  
      this.$refs.modalDetallesInversion.show();
        this.loadDetails();
  
  
    },
    methods: {
      closeDetalles(){
            this.$eventBus.$emit('closeModalDetallesInversion')
        },
     

    loadDetails(){
       this.loading=true;


     const userId_json = {
        userId: this.userId, type: this.optionFondos }

      const user_string = JSON.stringify(userId_json)

        const dataEncripted = this.$encryptBackoffice.encrypt(user_string)

            this.$https.post('/inversion/getGananciasDiarias/', { tokenAuth: this.tokenAuth, datos: dataEncripted }).then(response => {
                

              if (response.data.code == 200) {
                this.loading = false;

              
             

                  this.ganancias= Number(response.data.ganancias).toFixed(2);
                  this.days= response.data.days;

                  this.timeRestante= this.days+"/"+ this.timeDays + " días"
                  this.gananciasObtenidas= "$" + this.ganancias +"/"+ this.dineroTotalRecibir + " USD"
      

             
              } else {




                if (response.data.code == 401) {

                    this.$toast.error(response.data.message, {
                    position: 'top-right',
                    timeout: 3010,
                    closeOnClick: true,
                    pauseOnFocusLoss: false,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.4,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: 'button',
                    icon: true,
                    rtl: false,
                  })
                  
                  localStorage.removeItem('userData')

                  

                  
                  

                  

                  
                  

                  // Redirect to login page
                  this.$router.push({ name: 'auth-login' })
                } else {
                  this.loadDetails();
                }
              }
            }).catch(error => {
                this.loadDetails();
            })


            },

  
     
}

  }
  </script>
  
  <style lang="scss">
  
  
  
  
  
  </style>
  