<template>

    <div>
  
  
    <b-modal
        ref="modalAumentarFondos"
        centered
        hide-footer
        title="Aumento de saldo USDT al fondo"
        size="lg"
        @hidden="closeModalAumentarFondos"
      >
        <div class="d-block text-center">

          

            <b-row>

 

                    <b-col cols="12" sm="12" md="12" lg="6" xl="6">

           

                        <b-alert
                                    variant="secondary"
                                    show
                                    style="margin-top:0px;"
                                    >
                                    <div class="alert-body">
                                      <b-skeleton v-if="loading" style="margin-bottom:0px" width="100%" height="40px"  ></b-skeleton>

                                    <p v-else class="saldoDisponible">{{saldoDisponible}}<small class="currency2">USDT</small></p>
                                    <p style="font-size: 12px;    margin: 0;">Saldo disponible de su billetera</p>


                                    </div>
                                    </b-alert>

                                    <b-form
                                autocomplete="off"
                                @submit.prevent="onSubmit"
                              >
    
                              <b-row>
    
                                     <b-col
                                        cols="12"
                                          sm="12"
                                          md="12"
                                          lg="12"
                                          xl="12"
                                        >
    
                                        
    
                                                    <p
                                            class="textLabel"
                                            style="text-align: center;margin-top: 10px;margin-bottom: 5px;"
                                          >
                                            ¿Cuánto dinero vas a aumentar al fondo? (*):</p>

                                            <b-skeleton v-if="loading" style="margin-bottom:0px" width="100%" height="60px"  ></b-skeleton>

    
                                            <AutoNumericVue
                                            v-else
                                            v-model="mount"
                                            type="text" inputmode="decimal"
                                            required
                                            :readonly="isDisabled"
                                            autocus
                                            @input="handleInput2"
                                              @blur="handleInput"
                                            class="autonumeric_input"
                                            :options="json_opciones"
                                            :placeholder="'$0.00'"
                                        ></AutoNumericVue>
    
                                        </b-col>


    
                                           <b-col
                                                    sm="12"
                                                    md="12"
                                                    lg="12"
                                                    xl="12"
                                                  >
                                                    <p
                                                      class="textLabel"
                                                      style="text-align: center;margin-top: 5px;margin-bottom: 10px;"
                                                    >
                                                      Ingrese su código PIN (*): <feather-icon
                                                                        icon="HelpCircleIcon"
                                                                        size="16"
                                                                          v-b-popover.hover.bottom.html="'Si aún no tiene registrado un código PIN o se le olvidó cual era, puede dirigirse a <b>Mi Perfil > Código PIN de seguridad </b> para registrarlo o modificarlo'"
                                                                        title="Código PIN de seguridad"
                                                                        style="margin-right: 5px;cursor:pointer;"
                                                                        /> </p>
    
                                                    <PincodeInput
                                                      v-model="pin"
                                                      placeholder="-"
                                                      :length="6"
                                                      :autofocus="false"
                                                      :disabled="isDisabled"
                                                      :secure="true"
                                                      required
                                                    />
    
                                                  </b-col>
    
                                
    
    
    
                                                  <b-col
                                                  cols="12"
                                                    sm="12"
                                                    md="12"
                                                    lg="12"
                                                    xl="12"
                                                  >
                                                  <b-skeleton v-if="loading" style="margin-bottom:0px" width="100%" height="40px"  ></b-skeleton>

                                                    <b-button
                                                    v-else
                                                      id="btn_aumentar"
                                                      ref="btn_aumentar"
                                                      type="submit"
                                                      variant="primary"
                                                      block
                                                      :disabled="isDisabled"
                                                    >
    
                                                      Aumentar saldo al fondo
    
                                                    </b-button>
    
                                                  </b-col>
    
                                                  <b-col
                                cols="12"
                                  sm="12"
                                  md="12"
                                  lg="12"
                                  xl="12">

                                <b-alert
                                    variant="primary"
                                    show
                                    style="margin-top:15px"
                                  >
                                    <div class="alert-body">

                                      <b-skeleton v-if="loading" style="margin-bottom:10px" width="100%" height="10px"  ></b-skeleton>
                                          <p v-else style="text-align: left;font-size:12px; margin-bottom: 0px;"><b>Nota: </b> Solo podrá aumentar su fondo de ahorro una vez al mes hasta un monto máximo de <b>${{maxFondoAhorro}} USDT</b> </p>

                                    </div>
                                  </b-alert>


                                </b-col>
    
    
                              </b-row>
                              
                              
                              </b-form>


                  

                    </b-col>

                    
                    
                    <b-col cols="12" sm="12" md="12" lg="6" xl="6">

                   

                           
                                <p style="font-weight: bold; font-size:16px; text-align: center; margin-top:0px;margin-bottom:10px">Ganancias que recibirá</p>

                      <hr>

            
                        <div class="table-responsive " style="margin: 0;">
                            <table class="table table-bordered" style="text-align: center;">

                            <tbody>

                                <tr>

                                    <td class="background_table">Fondo total:</td>
                                    <td class="background_td_table"> <p style="font-size:16px; margin: 0px;text-align: right;"><span style="font-size:12px">$</span>{{fondoFinal}} <span style="font-size:10px">USDT</span></p></td>
                                    </tr>

                                <tr>

                                <td class="background_table">Ganancias diarias:</td>
                                <td class="background_td_table"> <p style="font-size:16px; margin: 0px;text-align: right;"><span style="font-size:12px">$</span>{{dineroDiarioRecibir}} <span style="font-size:10px">USDT</span></p></td>
                                </tr>

                                <tr>

                                    <td class="background_table">Ganancias cada 30 días:</td>
                                    <td class="background_td_table"> <p style="font-size:16px; margin: 0px;text-align: right;"><span style="font-size:12px">$</span>{{dineroMensualRecibir}} <span style="font-size:10px">USDT</span></p></td>
                                    </tr>

                                <tr>

                                <td class="background_table">Ganancias dentro de {{timeDays}} días:</td>
                                <td class="background_td_table"> <p style="font-size:16px; margin: 0px;text-align: right;"><span style="font-size:12px">$</span>{{dineroTotalRecibir}} <span style="font-size:10px">USDT</span></p></td>
                                
                                </tr>

                                <tr>

                                <td class="background_table">Total a recibir: <br>(incluye comisión)</td>
                                <td class="background_td_table"> <p style="font-size:16px; margin: 0px;text-align: right;"><span style="font-size:12px; ">$</span>{{dineroTotalRecibirComision}} <span style="font-size:10px">USDT</span></p></td>

                                </tr>





                            
                            </tbody>
                            </table>
                            </div>

                            <b-alert variant="secondary" style="margin-top: 5px !important; " show>
                          <div class="alert-body">

                    

                                <p style="font-size: 12px; margin-bottom:0;"> Este fondo se maneja en USDT y brindado por EQCoop System LLC. Haremos la conversión de sus fondos de manera automática. Recuerde que el mercado de inversiones puede tener caídas que serán notificadas a los clientes a través de los diferentes medios de comunicación de manera formal.</p>


                          
                        
                          
                          </div>
                        </b-alert>
                    
                
                    </b-col>


              



                
            </b-row>

 
  
        </div>
  
      </b-modal>
  
  
  </div>
  
  </template>
  
  <script>
  import {
    VBTooltip,VBPopover, BButton, BRow, BCol, BCard, BSkeletonImg,BSkeleton,BSkeletonTable, BForm, BAvatar, BLink, BFormSelect, BAlert
  } from 'bootstrap-vue'
  import Saldo from './Saldo.vue'
  import PincodeInput from 'vue-pincode-input'
    import AutoNumericVue from 'autonumeric-vue/src/components/AutoNumericVue';

  export default {
    components: {
      BButton,
      
      BSkeletonTable, BForm,
      PincodeInput,
      AutoNumericVue,
      Saldo,
      BAlert,
      BLink,
      BAvatar,
      BSkeleton,
      BSkeletonImg,
      BRow,
      BCol,
      BCard,
      BFormSelect
    },
      directives: {
            'b-popover': VBPopover,
      'b-tooltip': VBTooltip,
    },
    props: ['userId','tokenAuth', 'timeDays','optionFondos','comision','balance','idFondo',  'rendimiento'],
    data() {


  
      return {
        pin:"",
            isDisabled:false,
            mount:"",
            mountCalculado:0,
            json_opciones:{},
       loading:false,
       saldoDisponible:"0.00",
       isProfileVerificated:false,
       dineroMensualRecibir:"0.00",
            dineroTotalRecibir:"0.00",
            dineroDiarioRecibir:"0.00",
            dineroTotalRecibirComision:"0.00",
            fondoFinal: this.balance,
            maxFondoAhorro:0,

       
      
      }
    },
    computed: {
  
  
  
    },
    watch: {
  
 
  
  
  
    },
    mounted() {

        let porcentaje=Number(this.rendimiento)/Number("100");

     
          this.dineroMensualRecibir= Number(Number(this.balance)  * Number(porcentaje)).toFixed(2);
      
          this.dineroDiarioRecibir=  Number(Number(this.dineroMensualRecibir) / Number("30")).toFixed(2);

      

          this.dineroTotalRecibir= Number(Number(this.dineroDiarioRecibir) * Number(this.timeDays)).toFixed(2);//esta es lo que recibe en 3 meses

          let mountComision= (Number(this.balance) * Number(this.comision))/Number("100");



          this.dineroTotalRecibirComision=  Number(Number(this.balance) - Number(mountComision) + Number(this.dineroTotalRecibir)).toFixed(2);

  
     
      this.$refs.modalAumentarFondos.show();



   this.getBalance();


  
  
    },
    methods: {

      onSubmit(event) {
          event.preventDefault();

  
            this.mount= Number(this.mount).toFixed(2);
    
    
          const userId_json = {
            userId: this.userId, pin: this.pin, mount: this.mount, id:this.idFondo
          }
    
    
    
          const user_string = JSON.stringify(userId_json)
    
          const dataEncripted = this.$encryptBackoffice.encrypt(user_string)
        
          this.isDisabled = true
    
          document.getElementById('btn_aumentar').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Procesando'
    
          this.$https.post('/inversion/updateAhorro/', { tokenAuth: this.tokenAuth, datos: dataEncripted}).then(response => {
          this.isDisabled = false
    
        
      
            document.getElementById('btn_aumentar').innerHTML = 'Aumentar saldo a este fondo';
   
            if (response.data.code == 200) {
    

    
              this.$toast.success(response.data.message, {
                position: 'top-right',
                timeout: 3010,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.4,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: 'button',
                icon: true,
                rtl: false,
              });

              this.$refs.modalAumentarFondos.hide();
        
           
    
              this.$eventBus.$emit('reiniciarSaldoInversion', this.optionFondos);
             
    
    
    
            } else {
              this.isDisabled = false
    
              this.$toast.error(response.data.message, {
                position: 'top-right',
                timeout: 3010,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.4,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: 'button',
                icon: true,
                rtl: false,
              })
    
              if (response.data.code == 401) {
                
                localStorage.removeItem('userData')

                 

    
                
                
    
                // Redirect to login page
                this.$router.push({ name: 'auth-login' })
              } else if (response.data.code == 503) {
                this.pin = ''
              }else if(response.data.code == 501){

                this.$refs.modalAumentarFondos.hide();
                      this.$swal({
                          title: 'Tu perfil debe estar verificado para realizar esta acción',
                          text: `¿Deseas verificar tu cuenta ahora?`,
                          icon: 'warning',
                          showCancelButton: true,
                          confirmButtonText: 'Verificar cuenta',
                          cancelButtonText: 'No',
                          customClass: {
                            confirmButton: 'btn btn-primary',
                            cancelButton: 'btn btn-outline-secondary ml-1',
                          },
                          buttonsStyling: false,
                        }).then(result => {
                          if (result.value) {
    
                            this.$router.push({ name: 'perfil', hash: '#verificacion' })
    
                          }
                        })
                    }else if(response.data.code=== 404){

                      this.$refs.modalAumentarFondos.hide();
  

                    }
            }
          }).catch(error => {
            this.$toast.error("Ocurrió un error inesperado", {
              position: 'top-right',
              timeout: 3010,
              closeOnClick: true,
              pauseOnFocusLoss: false,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.4,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: 'button',
              icon: true,
              rtl: false,
            })
          })
    
      
    
        
        },

        handleInput2(value){



        if(value === "" || value === "null" || value === null  ){


        
        

            this.mount="";
            this.mountCalculado= Number(this.balance) +  Number(this.mount);
            this.fondoFinal= Number(Number(this.balance) + Number(this.mount)).toFixed(2);
            



        }else{

          
            this.mountCalculado= Number(this.balance) +  Number(value);
            this.fondoFinal= Number(Number(this.balance) + Number(value)).toFixed(2);

        
          
            
        }



                let porcentaje=Number(this.rendimiento)/Number("100");

            this.dineroMensualRecibir= Number(Number(this.mountCalculado)  * Number(porcentaje)).toFixed(2);

            this.dineroDiarioRecibir=  Number(Number(this.dineroMensualRecibir) / Number("30")).toFixed(2);



            this.dineroTotalRecibir= Number(Number(this.dineroDiarioRecibir) * Number(this.timeDays)).toFixed(2);//esta es lo que recibe en 3 meses

                let mountComision= (Number(this.mountCalculado) * Number(this.comision))/Number("100");



            this.dineroTotalRecibirComision=  Number(Number(this.mountCalculado) - Number(mountComision) + Number(this.dineroTotalRecibir)).toFixed(2);

            

  

},

handleInput () {

    
        var t = this.mount;

        if(t === "" || t === "null" || t === null  ){



        t=0;

        this.mountCalculado= Number(this.balance) +  Number(t);
            
      
        this.fondoFinal= Number(Number(this.balance) + Number(t)).toFixed(2);
            


    }else{
      if(t < Number("1")){

        this.$toast.warning("El monto mínimo para el aumento del fondo de ahorro es de $1.00 USD", {
              position: 'top-right',
              timeout: 3010,
              closeOnClick: true,
              pauseOnFocusLoss: false,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.4,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: 'button',
              icon: true,
              rtl: false,
              });

              this.mount ="";
this.mountCalculado=0;

        }else{

          this.mountCalculado= Number(this.balance) +  Number(t);
                this.fondoFinal= Number(Number(this.balance) + Number(t)).toFixed(2);
                

        }

      
    }



if(Number(t) > Number(this.saldoDisponible)){



this.$toast.error("El monto $"+t+" USD supera su saldo de billetera.", {
position: 'top-right',
timeout: 3010,
closeOnClick: true,
pauseOnFocusLoss: false,
pauseOnHover: true,
draggable: true,
draggablePercent: 0.4,
showCloseButtonOnHover: false,
hideProgressBar: true,
closeButton: 'button',
icon: true,
rtl: false,
});

this.mount ="";
this.mountCalculado=0;

}

let porcentaje=Number(this.rendimiento)/Number("100");

this.dineroMensualRecibir= Number(Number(this.mountCalculado)  * Number(porcentaje)).toFixed(2);

this.dineroDiarioRecibir=  Number(Number(this.dineroMensualRecibir) / Number("30")).toFixed(2);



this.dineroTotalRecibir= Number(Number(this.dineroDiarioRecibir) * Number(this.timeDays)).toFixed(2);//esta es lo que recibe en 3 meses

let mountComision= (Number(this.mountCalculado) * Number(this.comision))/Number("100");

this.dineroTotalRecibirComision=  Number(Number(this.mountCalculado) - Number(mountComision) + Number(this.dineroTotalRecibir)).toFixed(2);


},

  
  closeModalAumentarFondos(){

   
    
    this.$eventBus.$emit('reiniciarModalAumentarFondos')
  
  },

  

  getBalance(){
      this.loading=true;
      const userId_json = {
                    userId: this.userId, type: this.optionFondos }

                    const user_string = JSON.stringify(userId_json)
                    const dataEncripted = this.$encryptBackoffice.encrypt(user_string)

    this.$https.post('/inversion/getBalanceInversion/', { tokenAuth: this.tokenAuth, datos: dataEncripted }).then(response => {
        

       
      if (response.data.code == 200) {
 
         
          this.isProfileVerificated=response.data.isProfileVerificated;

          if(this.isProfileVerificated){

            this.saldoDisponible= response.data.balance;
            this.loading = false;
            this.maxFondoAhorro= response.data.maxFondoAhorro;

            this.json_opciones={allowDecimalPadding: "false", currencySymbol: "$", decimalCharacterAlternative: ",",decimalCharacter:".",   minimumValue: 0, maximumValue:this.maxFondoAhorro, modifyValueOnWheel: false };
    


          }else{
            this.$eventBus.$emit('reiniciarModalAumentarFondos');
            this.$swal({
                      title: 'Tu perfil debe estar verificado para realizar esta acción',
                      text: `¿Deseas verificar tu cuenta ahora?`,
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonText: 'Verificar cuenta',
                      cancelButtonText: 'No',
                      customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-secondary ml-1',
                      },
                      buttonsStyling: false,
                    }).then(result => {
                      if (result.value) {

                        this.$router.push({ name: 'perfil', hash: '#verificacion' })

                      }
                    })
          }
    

      } else {

        this.$toast.error(response.data.message, {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })


        if (response.data.code == 401) {

               
          localStorage.removeItem('userData') 
    
          // Redirect to login page
          this.$router.push({ name: 'auth-login' })
        } else {
           this.getBalance();
        }
      }
    }).catch(error => {
         this.getBalance();
    })
    }

  
     
}

  }
  </script>
  
  <style lang="scss">
  
  
  
  
  
  </style>
  