<template>

    <div>
        <b-skeleton v-if="loading" width="100%" class="div_anuncios_dashboard" style="margin-bottom:0px" ></b-skeleton>
        <carousel v-else  :autoplay="true" :dots="false"  :nav="false" :items="1" :margin="margin" :responsive="{0:{items:1,nav:false},600:{items:1,nav:false},770:{items:1,nav:false},1200:{items:1,nav:false}}">

     
                <content-carousel :anuncio="anuncio" v-for="(anuncio) in anuncios" :key="anuncio.orden"/>






                </carousel>

    </div>



    
    </template>
    
    <script>
    import {
    BSkeleton
    } from 'bootstrap-vue'

    import  ContentCarousel from './ContentCarousel.vue'
    import carousel from 'vue-owl-carousel'

 
    export default {
    components: {
    carousel,
    ContentCarousel,
    BSkeleton
    },
    directives: {
    },
    props: [],
    data() {
      
          let margin=10;
              if(this.$isMobile){

                margin=5;

              }
    
    return {

        loading:true,
        margin:margin,

            isMobile:this.$isMobile,
            anuncios: [],
            empty:false,

    }
    },
    computed: {
    
    },
    watch: {
    
    
    
    },
    created(){
    
    
    
    
    
    
    },
    mounted() {

        this.loadAnuncios();
    
    },
    methods: {

        loadAnuncios(){
            this.$https.post('/locals/getAnunciosDashboard/').then(response => {

              
                                    
                if (response.data.code == 200) {

                    this.loading = false;

                    this.anuncios= response.data.anuncios;
       
                } else {



                    if(response.data.code == 404){

                  

              
                    this.loading= false;
                    this.empty=true;
                    this.anuncios=[];
                 

                  
                    }else{


                        this.loadAnuncios();
                    

                    
                    }
                    
                
                }
                }).catch(error => {
                this.loadAnuncios();
                })
          }

    }
    }
    
    </script>
    
    <style lang="scss">
    
    
    
    
    
    
    
    </style>
    